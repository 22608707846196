import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import styled from 'styled-components';
import { COLORS } from 'consts/styles';
import { hasUserPrivilege } from 'util/helpers/privilegeHelper';
import {
  AutoAssignInputModelInput,
  AutoAssignStatus,
  EpisodeAutoAssign,
  ILookupValue,
  QuickNoteType,
} from 'graphql/graphqlTypes';
import { useAddEpisodeBalancerCriteriaToQueueMutation } from 'graphql/hooks/addEpisodeBalancerCriteriaToQueue';
import { IMessageState } from 'features/letters';
import { launchCoachEducation } from 'features/education/coach';
import { openLetters } from 'store/ui/modals/letters';
import { openQuickNote } from 'store/ui/modals/quickNote';
import { showErrorPopup } from 'store/errorPopup/errorPopupSlice';
import { IRole, Privilege } from 'store/roles/types';
import Icon, { ICONS } from 'components/icon';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';
import Snackbar from 'components/snackbar';
import { EpisodeDistributeForm } from 'components/episodeDistributeDialog/types';
import { toAddEpisodeBalancerCriteriaMutationVariables } from 'components/episodeDistributeDialog/EpisodeDistributeDialog.helper';
import ExportToggleButton from 'components/home/grid/ExportToggleButton';
import EpisodeDistributeDialog from 'components/episodeDistributeDialog';
import IGridHeaderModel from './GridHeaderModel';
import { useEpisodeAutoAssignMutation } from 'graphql/hooks/episodeAutoAssign';
import { IState } from 'store';
import { useLazyGetAutoAssignEpisodeQuery } from 'graphql/hooks/getAutoAssignEpisode';
import { find } from 'lodash';
import { setUserRole } from 'store/roles/middlewares';
import { useNavigateToAutoAssignState } from './useNavigateToAutoAssignState';

const StyledDivider = styled(Divider)`
  height: 24px;
  margin: 0;
`;

interface IconButtonConfig {
  canShow: boolean;
  title: string;
  icon: ICONS;
  onClick: () => void;
  disabled?: boolean;
  testId?: string;
  cypressId?: string;
}

const HeaderPanelActionButtons = (props: IGridHeaderModel) => {
  const {
    onMultiEdit,
    patientSelected,
    isEditDisabled,
    patientId,
    episodeId,
    isFaxRow,
    handleTriggerExportGridData,
  } = props;
  const dispatch = useDispatch();
  const [addEpisodeBalancerCriteria] =
    useAddEpisodeBalancerCriteriaToQueueMutation();
  const [
    getAutoAssignEpisode,
    {
      data: episodeToAutoAssign,
      isLoading: isEpisodeDataLoading,
      isSuccess: isEpisodeDataSuccess,
    },
  ] = useLazyGetAutoAssignEpisodeQuery();
  const [trigger, setTrigger] = useState(0);
  const [autoAssignEpisode] = useEpisodeAutoAssignMutation();
  const [distributeDialogOpen, setDistributeDialogOpen] = useState(false);
  const [message, setMessage] = useState<IMessageState | null>();
  const canEditSelected = hasUserPrivilege(Privilege.EditSelected);
  const canAddLetters = hasUserPrivilege(Privilege.AddLetters);
  const canAddNote = hasUserPrivilege(Privilege.AddMemberEpisodeNotes);
  const canEducate = hasUserPrivilege(Privilege.Education);
  const canExportGridData = hasUserPrivilege(Privilege.ExportHomeScreen);
  const canBulkAssign = hasUserPrivilege(Privilege.BulkAssign);
  const canAutoAssign = hasUserPrivilege(Privilege.AutoAssign);
  const showDivider =
    canEditSelected ||
    canAddLetters ||
    canAddNote ||
    canEducate ||
    canBulkAssign ||
    canAutoAssign;

  const actionsDisabled = !patientSelected || isFaxRow;
  const lettersDisabled = actionsDisabled || episodeId <= 0;
  const currentCareSiteId = useSelector(
    (state: IState) => state.user.currentUser.careSiteId
  );
  const navigateToAutoAssignState = useNavigateToAutoAssignState();
  const isLoggedIn = useSelector((state: IState) => state.user.isLoggedIn);
  const primaryRoleId = useSelector(
    (state: IState) => state.roles.primaryRoleId
  );
  const user = useSelector((state: IState) => state.user.auth);
  const sessionId = useSelector(
    (state: IState) => state.user.currentUser.sessionId
  );

  const roles = useSelector((state: IState) => state.roles.items);
  const userLoggedInRoleId = useSelector(
    (state: IState) => state.user.currentUser.userRoleId
  );
  const userLoggedInRole = useSelector(
    (state: IState) => state.user.currentUser.role
  );
  const displayRole = find(roles, { id: userLoggedInRoleId });

  useEffect(() => {
    if (!isEpisodeDataLoading && isEpisodeDataSuccess && trigger > 0) {
      const result = episodeToAutoAssign?.getAutoAssignEpisode;
      const careSite =
        result && (result.careSiteId ?? 0) > 0
          ? ({
              id: result.careSiteId,
              name: result.careSiteName,
            } as ILookupValue)
          : undefined;
      const valid = showValidationPopup(result?.status, careSite);
      if (valid && result) {
        autoAssign(result);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    episodeToAutoAssign,
    isEpisodeDataLoading,
    isEpisodeDataSuccess,
    trigger,
  ]);

  const autoAssign = (episode: EpisodeAutoAssign) => {
    const autoAssignModel: AutoAssignInputModelInput = {
      episodeId: episode?.episodeId ?? 0,
      episodeNumber: episode?.episodeNumber ?? '',
      workflowInstanceId: episode?.workflowInstanceId ?? '',
    };

    autoAssignEpisode({ autoAssignModel }).then((assignResult) => {
      if ('data' in assignResult) {
        const valid = showValidationPopup(assignResult.data.episodeAutoAssign);
        if (valid) {
          navigateToEpisode(episode);
        }
      }
    });
  };

  const navigateToEpisode = (episode: EpisodeAutoAssign) => {
    if (!checkForSameCareSite(episode.careSiteId ?? 0)) {
      switchCareSite(episode.careSiteId ?? 0);
    }

    navigateToAutoAssignState(episode);
  };

  const checkForSameCareSite = (careSiteId: number) => {
    return careSiteId === currentCareSiteId;
  };

  const switchCareSite = (careSiteId: number) => {
    const newRole = find<IRole>(roles, {
      careSiteId: careSiteId,
      displayName: displayRole?.displayName,
    });

    if (newRole && newRole.id !== userLoggedInRoleId) {
      dispatch(
        setUserRole(
          newRole.id,
          sessionId,
          user,
          primaryRoleId ?? 0,
          isLoggedIn,
          false
        )
      );
    }
  };

  const showValidationPopup = (
    status?: AutoAssignStatus | null,
    careSite?: ILookupValue
  ) => {
    const messages = getValidationMessages(status, careSite);

    if (messages.length > 0) {
      dispatch(showErrorPopup({ message: messages.join('\n') }));
      return false;
    }

    return true;
  };

  const getValidationMessages = (
    status?: AutoAssignStatus | null,
    careSite?: ILookupValue
  ): string[] => {
    const messages: string[] = [];

    addMessage(messages, getStatusMessage(status));

    if (careSite) {
      addMessage(messages, getRoleValidationMessage(careSite));
    }

    return messages;
  };

  const addMessage = (messages: string[], message: string) => {
    if (message) {
      messages.push(`• ${message}`);
    }
  };

  const getRoleValidationMessage = (careSite: ILookupValue): string => {
    return doesRoleExistForCareSite(careSite.id)
      ? ''
      : `The logged-in role (${userLoggedInRole}) does not exist for the care site (${careSite.name}) associated with the auto-assign episode.`;
  };

  const doesRoleExistForCareSite = (careSiteId: number): boolean => {
    return roles.some(
      (r) =>
        r.careSiteId === careSiteId &&
        r.displayName === displayRole?.displayName
    );
  };

  const getStatusMessage = (status?: AutoAssignStatus | null): string => {
    switch (status) {
      case AutoAssignStatus.AssignmentFailed:
        return 'Assignment of ownership failed';
      case AutoAssignStatus.NoRoleMapping:
        return 'Administrator has not been set up Role - Care Stage mapping';
      case AutoAssignStatus.Failed:
        return 'Failed to retrieve episode for auto-assign';
      case null:
        return 'Unexpected error occurred while auto-assigning episode.';
      default:
        return '';
    }
  };

  const handleClickOpenQuickDialog = (type: QuickNoteType) => {
    dispatch(
      openQuickNote({
        patientId: patientId,
        type: type,
        episodeId: episodeId ?? 0,
        baseQuickNoteId: null,
      })
    );
  };

  const handleCoachClick = async () => {
    await launchCoachEducation(
      patientId,
      () => void 0,
      (err) => {
        if (err) {
          dispatch(showErrorPopup({ message: err }));
        }
      }
    );
  };

  const handleBulkAssign = () => {
    setDistributeDialogOpen(true);
  };

  const handleDistribute = (value: EpisodeDistributeForm) => {
    const episodeBalancerCriteria =
      toAddEpisodeBalancerCriteriaMutationVariables(value);
    addEpisodeBalancerCriteria({ episodeBalancerCriteria }).then((result) => {
      setDistributeDialogOpen(false);
      if ('data' in result) {
        setMessage({
          active: true,
          type: 'success',
          title: 'Success',
          text: 'Selected criteria has been successfully queued for execution.',
        });
      } else if ('error' in result) {
        dispatch(
          showErrorPopup({
            message: 'Error occurred while queuing for episode distribution.',
          })
        );
      }
    });
  };

  const handleAutoAssign = () => {
    setTrigger((prev) => prev + 1);
    getAutoAssignEpisode();
  };

  const getIconColor = (status: boolean) =>
    status ? COLORS.GREY60 : COLORS.SYMPHONY_BLUE;

  const buttonsConfig: IconButtonConfig[] = [
    {
      canShow: canAddNote,
      title: 'Episode note',
      icon: ICONS.Episode_Note,
      onClick: () => handleClickOpenQuickDialog(QuickNoteType.Episode),
      disabled: actionsDisabled,
      testId: 'episode-note',
    },
    {
      canShow: canAddNote,
      title: 'Member note',
      icon: ICONS.Member_Note,
      onClick: () => handleClickOpenQuickDialog(QuickNoteType.Patient),
      disabled: actionsDisabled,
      testId: 'member-note',
    },
    {
      canShow: canAddLetters,
      title: 'Letters',
      icon: ICONS.Letters,
      onClick: () => dispatch(openLetters({ episodeId, patientId })),
      disabled: lettersDisabled,
      testId: 'letter-button',
    },
    {
      canShow: canEditSelected,
      title: 'Edit selection',
      icon: ICONS.Edit,
      onClick: () => onMultiEdit(),
      disabled: isEditDisabled,
      testId: 'multi-edit-button',
      cypressId: 'edit-selected',
    },
    {
      canShow: canBulkAssign,
      title: 'Bulk Assign',
      icon: ICONS.AssignUser,
      onClick: handleBulkAssign,
    },
    {
      canShow: canAutoAssign,
      title: 'Auto Assign',
      icon: ICONS.AutoAssign,
      testId: 'auto-assign-button',
      onClick: handleAutoAssign,
    },
    {
      canShow: canEducate,
      title: 'Education/Healthwise',
      icon: ICONS.Educate,
      onClick: () => {
        handleCoachClick();
      },
    },
  ];

  const renderHeaderPanelButtons = (buttons: IconButtonConfig[]) => {
    return buttons
      .filter(({ canShow }) => canShow)
      .map(({ title, icon, onClick, disabled, testId, cypressId }, index) => (
        <CustomTooltip key={testId || title || index} title={title}>
          <span>
            <IconButton
              aria-label={title.toLowerCase().replace(/\s/g, '-')}
              {...(testId && { 'data-testid': testId })}
              {...(cypressId && { 'data-cy': cypressId })}
              disabled={disabled ?? false}
              onClick={onClick}
              size="large"
            >
              <Icon icon={icon} size={24} color={getIconColor(!!disabled)} />
            </IconButton>
          </span>
        </CustomTooltip>
      ));
  };

  return (
    <>
      {showDivider && (
        <StyledDivider orientation="vertical" data-testid="panel-divider" />
      )}

      <div
        style={{ display: 'flex', marginRight: '-10px', marginLeft: '-10px' }}
      >
        {renderHeaderPanelButtons(buttonsConfig)}
        {canExportGridData ? (
          <ExportToggleButton
            handleTriggerExportGridData={handleTriggerExportGridData}
            buttonMessage="Export Data"
          />
        ) : null}
      </div>
      {distributeDialogOpen && (
        <EpisodeDistributeDialog
          open={distributeDialogOpen}
          onDistribute={handleDistribute}
          onClose={() => {
            setDistributeDialogOpen(false);
          }}
        />
      )}
      {message && (
        <Snackbar
          icon={message.type === 'success' ? ICONS.Checkmark : ICONS.Warning}
          {...message}
          open={message?.active}
          onClose={() => setMessage({ ...message, active: false })}
          duration={message.type === 'success' ? 4000 : 'infinite'}
        />
      )}
    </>
  );
};

export default HeaderPanelActionButtons;
