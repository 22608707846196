import React from 'react';
import {
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import { Grid } from '@mui/material';
import ActionLink from 'components/actionlink';
import AttachmentGroupDeleteLink from 'components/actionlink/AttachmentGroupDeleteLink';

const AttachmentGroupActionsFormatter = (
  props: DataTypeProvider.ValueFormatterProps
) => {
  const { row, value } = props;
  const id = (row.attachment.id ?? 0) as number;
  const fileName = row.attachment.name;
  if (id < 0) {
    return null;
  }

  return (
    <Grid container direction="row">
      <ActionLink
        key={`AttachmentView${id}`}
        type="AttachmentView"
        entityId={id}
        fileName={fileName}
        label="View/Print"
      />
      <AttachmentGroupDeleteLink
        attachmentId={id}
        fileName={fileName}
        onDeleteCallback={value.onDeleteCallback}
        isDisabled={value.isReadOnly}
      />
    </Grid>
  );
};

export const AttachmentGroupActionsProvider = (
  props: DataTypeProviderProps
) => (
  <DataTypeProvider
    formatterComponent={AttachmentGroupActionsFormatter}
    {...props}
  />
);
