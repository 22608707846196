import React from 'react';
import { Review } from '@PerformanceClincial/checklist-ui';
import { ChecklistStorageType } from 'store/actions/types';
import { useSelector } from 'react-redux';
import { IState } from 'store';
import { ChecklistViewModel } from 'graphql/graphqlTypes';

export interface ISectionsProps {
  checklist?: ChecklistViewModel | null;
  storageType: ChecklistStorageType;
  hideNumbers?: boolean;
  actionInstanceId?: string | null;
}

const ReviewSections = (props: ISectionsProps) => {
  const { storageType, hideNumbers, actionInstanceId } = props;
  const isMozartFlow =
    storageType === ChecklistStorageType.MOZART ||
    storageType === ChecklistStorageType.VIEW;

  const checklist = useSelector((state: IState) =>
    props.checklist
      ? props.checklist
      : state.checklist.documentsState[storageType]?.checklist
  );

  const printDocuments = useSelector(
    (state: IState) => state.ui.print.documents
  );

  return (
    checklist && (
      <Review
        checklist={checklist}
        hideNumbers={hideNumbers}
        actionInstanceId={actionInstanceId}
        isMozartFlow={isMozartFlow}
        isPrintOptimized={printDocuments.isOptimizedView}
      />
    )
  );
};

export default ReviewSections;
