import React from 'react';
import { Box, Typography } from '@mui/material';

import { ILookupValue, LookupValue } from 'graphql/graphqlTypes';
import { DeterminationDecision } from 'components/actions/sections/SectionBody/Items/HealthService/types';
import { HsrnDecisionBox } from '@PerformanceClincial/checklist-ui';
import { DecisionButtonGroup } from 'components/actions/sections/SectionBody/Items/HealthService/Components/DecisionButtonGroup';
import { RequiredReason } from 'components/actions/sections/SectionBody/Items/HealthService/Components/RequiredReason';
import ReasonSelect from 'components/actions/sections/SectionBody/Items/HealthService/Components/ReasonSelect';

export interface IManualReviewDecisionProps {
  updateGroupDecision: (value: DeterminationDecision) => void;
  groupDecision: DeterminationDecision;
  groupDecisionReadOnly?: boolean;
  pendingOnly?: boolean;
  canApprove?: boolean;
  canDenied?: boolean;
  canPartial?: boolean;
  determinationReasons: { [p: string]: ILookupValue[] };
  value?: LookupValue | null;
  updateValue: (reason: LookupValue | null) => void;
  showLabel?: boolean;
  reasonSelectReadOnly?: boolean;
  isRequired?: boolean;
  error?: boolean;
  determinationReasonName?: string | null;
  readOnly: boolean;
}

export const ManualReviewDecisionGroup = (
  props: IManualReviewDecisionProps
) => {
  const {
    updateGroupDecision,
    groupDecision,
    groupDecisionReadOnly,
    pendingOnly,
    canApprove,
    canDenied,
    canPartial,
    determinationReasons,
    value,
    updateValue,
    reasonSelectReadOnly,
    error,
    readOnly,
  } = props;

  return (
    <>
      <HsrnDecisionBox py="16px" data-testid="ManualReviewDecisionGroup">
        <Typography variant="body1">Decision</Typography>
        <DecisionButtonGroup
          updateGroupDecision={updateGroupDecision}
          groupDecision={groupDecision}
          readOnly={groupDecisionReadOnly || readOnly}
          pendingOnly={pendingOnly}
          canApprove={canApprove}
          canDenied={canDenied}
          canPartial={canPartial}
        />
        {groupDecision !== 'NonSet' && (
          <Box pt="24px" data-testid="Not-NonSet">
            <RequiredReason isRequired />
            <ReasonSelect
              determinationReasons={determinationReasons}
              groupDecision={groupDecision}
              value={value}
              readOnly={
                reasonSelectReadOnly ||
                (!canApprove && !canDenied && !pendingOnly) ||
                readOnly
              }
              updateValue={updateValue}
              width="100%"
              error={error && !readOnly}
            />
          </Box>
        )}
      </HsrnDecisionBox>
    </>
  );
};
export default ManualReviewDecisionGroup;
