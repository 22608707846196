import {
  MozartProgressState,
  MozartWorkflowUiModel,
} from 'graphql/graphqlTypes';
import { MozartStep } from '../../MozartStep/MozartStep';
import React from 'react';
import { MozartMessage } from '../../MozartAction/MozartMessage';
import styled from 'styled-components';
import { ShowModuleMessage } from './ShowModuleMessage';
import { formatDate } from 'util/helpers/dateTimeHelpers';
import { DATETIME_SECONDS_FORMAT } from 'components/constants';

export const StyledSectionWrapper = styled.div.attrs(() => ({
  'data-testid': 'mozart-show-message',
}))`
  margin-top: 20px;
`;

export const MozartShowMessage = ({
  message,
}: {
  message: MozartWorkflowUiModel;
}) => {
  const regex = /\[\[(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d{3})?Z?)\]\]/g;
  const outputMessage = message.showMessageValue?.replace(
    regex,
    (match, dateTime) => {
      // Convert to local time string
      return formatDate(new Date(dateTime), DATETIME_SECONDS_FORMAT);
    }
  );
  message.showMessageValue = outputMessage;

  if (
    message.showMessageKey === 'URL' &&
    message.state === MozartProgressState.Completed
  ) {
    return <ShowModuleMessage message={message} />;
  } else if (
    message.showMessageKey === 'Message' &&
    message.state === MozartProgressState.Completed
  ) {
    return (
      <StyledSectionWrapper>
        <MozartStep message={message} />
        <MozartMessage message={message.showMessageValue ?? ''} />
      </StyledSectionWrapper>
    );
  }
  return null;
};
