import React from 'react';
import { COLORS } from 'consts/styles';
import styled from 'styled-components';

export const NotificationIconStyledDiv = styled.div<{ $shortTop?: boolean }>`
  position: absolute;
  top: ${(props) => (props.$shortTop ? '2px' : '4px')};
  right: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  border-radius: 20px;
  background-color: ${COLORS.RED100};
`;

export const NotificationIconValue = styled.span`
  font-size: 10px;
  color: white;
  line-height: 8px;
`;

export const NotificationIcon = (props: {
  value: number | null | undefined;
  shortTop?: boolean;
}) => {
  return props.value && props.value > 0 ? (
    <NotificationIconStyledDiv $shortTop={props.shortTop}>
      <NotificationIconValue>{props.value}</NotificationIconValue>
    </NotificationIconStyledDiv>
  ) : (
    <></>
  );
};
