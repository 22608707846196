import React, { ReactElement } from 'react';
import {
  EditingCell,
  ILabelCellProps,
  LabelCell,
} from 'components/gridFormatters';
import { PrimaryCell } from './cells/primaryCell';
import { AddressStateCell } from './cells/addressStateCell';
import { EditCellValidationProps } from './contactsGrid';

export const attributeToType = {
  AlternateAddress1: 'ALTERNATE',
  AlternateAddress2: 'ALTERNATE',
  AlternateCity: 'ALTERNATE',
  AlternateState: 'ALTERNATE',
  AlternateZipCode: 'ALTERNATE',
  ThirdAddress1: 'THIRD',
  ThirdAddress2: 'THIRD',
  ThirdCity: 'THIRD',
  ThirdState: 'THIRD',
  ThirdZipCode: 'THIRD',
  EmailAddressAlternate: 'Alternate',
  AlternatePhone: 'Alternate',
  AlternatePhone2: 'Alternate 2',
};

export const columnToAttribute = {
  ALTERNATE: {
    address1: 'AlternateAddress1',
    address2: 'AlternateAddress2',
    city: 'AlternateCity',
    state: 'AlternateState',
    zip: 'AlternateZipCode',
  },
  THIRD: {
    address1: 'ThirdAddress1',
    address2: 'ThirdAddress2',
    city: 'ThirdCity',
    state: 'ThirdState',
    zip: 'ThirdZipCode',
  },
  Alternate: {
    address: 'EmailAddressAlternate',
    number: 'AlternatePhone',
  },
  'Alternate 2': {
    number: 'AlternatePhone2',
  },
};

export const addressColumns = [
  { name: 'address1', title: 'Address 1' },
  { name: 'address2', title: 'Address 2' },
  { name: 'city', title: 'City' },
  { name: 'state', title: 'State' },
  { name: 'zip', title: 'Zip' },
  { name: 'isPrimary', title: 'Primary' },
];

export const addressColumnExtension = [
  { columnName: 'address1', width: '20%' },
  { columnName: 'address2', width: '20%' },
  { columnName: 'city', width: '15%' },
  { columnName: 'state', width: '10%' },
  { columnName: 'zip', width: '10%' },
  { columnName: 'isPrimary', width: '60px' },
];

export const EditAddress = (
  props: EditCellValidationProps
): ReactElement<EditCellValidationProps> => {
  const { column } = props;
  switch (column.name) {
    case 'state':
      return <AddressStateCell {...props} />;
    case 'isPrimary':
      return <PrimaryCell {...props} />;
    default:
      return <EditingCell {...props} />;
  }
};

interface ILabelAddressProps extends ILabelCellProps {
  disabled?: boolean;
}

export const LabelAddress = ({ disabled, ...props }: ILabelAddressProps) => {
  if (props.column.name === 'isPrimary') {
    return <PrimaryCell {...props} disabled={disabled} />;
  }
  return <LabelCell {...props} />;
};
