import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetPatientFromAttachmentQueryVariables = Types.Exact<{
  attachmentId: Types.Scalars['Int'];
}>;

export type GetPatientFromAttachmentQuery = {
  __typename?: 'EntitiesQuery';
  getPatientFromAttachment?: {
    __typename?: 'SuggestPatient';
    id: number;
    lastName?: string | null;
    firstName?: string | null;
    fullName?: string | null;
    mRN: string;
    mRN2?: string | null;
    dOB: string;
  } | null;
};

export const GetPatientFromAttachmentDocument = `
    query getPatientFromAttachment($attachmentId: Int!) {
  getPatientFromAttachment(attachmentId: $attachmentId) {
    id
    lastName
    firstName
    fullName
    mRN
    mRN2
    dOB
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPatientFromAttachment: build.query<
      GetPatientFromAttachmentQuery,
      GetPatientFromAttachmentQueryVariables
    >({
      query: (variables) => ({
        document: GetPatientFromAttachmentDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetPatientFromAttachmentQuery,
  useLazyGetPatientFromAttachmentQuery,
} = injectedRtkApi;
