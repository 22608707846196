import { COLORS } from 'consts/styles';
import { BaseReminder } from 'graphql/graphqlTypes';
import moment from 'moment';

export const convertHexToRGBA = (hexCode: string, opacity: number) => {
  let hex = hexCode.replace('#', '');
  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  return `rgba(${r},${g},${b},${opacity / 100})`;
};

export enum AUTHORIZATION_STATUS_STYLE {
  Green = 'STATUS_GREEN',
  Orange = 'STATUS_ORANGE',
  Red = 'STATUS_RED',
  Grey = 'STATUS_GREY',
}

export const mapStatusToIconColor = (status: string) => {
  const statusNormalized = status?.toLowerCase();
  switch (statusNormalized) {
    case 'critical':
    case '1':
      return COLORS.RED100;
    case 'high':
    case '2':
      return COLORS.ORANGE100;
    case 'low':
    case '3':
      return COLORS.YELLOW100;
    case '4':
      return COLORS.COMPLETED_GREEN;
    case '5':
    case 'new':
      return COLORS.GREEN100;
    case '6':
      return COLORS.GREY100;
    default:
      return COLORS.GREEN100;
  }
};

const RedStatus = { background: COLORS.RED20, text: COLORS.RED160 };
const OrangeStatus = { background: COLORS.ORANGE20, text: COLORS.ORANGE160 };
const GreenStatus = { background: COLORS.GREEN20, text: COLORS.GREEN160 };
const GreyStatus = { background: COLORS.GREY25, text: COLORS.GREY100 };
const YellowStatus = { background: COLORS.YELLOW20, text: COLORS.YELLOW160 };

export const mapReminderColor = (status: string) => {
  const statusNormalized = status?.toLowerCase();
  switch (statusNormalized) {
    case 'critical':
    case '1':
      return RedStatus;
    case 'high':
    case '2':
      return OrangeStatus;
    case 'low':
    case '3':
      return YellowStatus;
    case '4':
      return GreenStatus;
    default:
      return GreyStatus;
  }
};

export const mapAuthStatusStyle = (status: AUTHORIZATION_STATUS_STYLE) => {
  switch (status) {
    case AUTHORIZATION_STATUS_STYLE.Red:
      return RedStatus;
    case AUTHORIZATION_STATUS_STYLE.Orange:
      return OrangeStatus;
    case AUTHORIZATION_STATUS_STYLE.Green:
      return GreenStatus;
    default:
      return GreyStatus;
  }
};

export const mapStatusToText = (
  status: string,
  hasLongText?: boolean,
  reminder?: boolean
) => {
  const statusNormalized = status?.toLowerCase();
  switch (statusNormalized) {
    case 'critical':
    case '1':
      if (hasLongText) {
        return 'Critical Importance. Red when overdue.';
      } else {
        return 'Critical';
      }
    case 'high':
    case '2':
      if (hasLongText) {
        return 'High Importance. Orange when overdue.';
      } else {
        return 'High';
      }
    case 'low':
    case '3':
      if (hasLongText) {
        return 'Low Importance, Yellow when overdue.';
      } else {
        return 'Low';
      }
    case '4':
    case 'onschedule':
      return 'On Schedule';
    case '5':
    case 'new':
      if (reminder) {
        return 'On Schedule';
      }
      return 'New';
    default:
      return 'Completed';
  }
};

export const mapReminderStatusToIconColor = (status: number) => {
  switch (status) {
    case 1:
      return COLORS.RED100;
    case 2:
      return COLORS.ORANGE100;
    case 3:
      return COLORS.YELLOW100;
    case 4:
      return COLORS.COMPLETED_GREEN;
    case 5:
      return COLORS.GREEN100;
    default:
      return COLORS.GREY100;
  }
};

export interface IReminderStatus {
  owner: { id: number };
  date: string;
  importanceId: number;
  isNew: boolean;
  isCompleted: boolean;
}

export const getReminderStatusAndDays = (
  reminder: IReminderStatus | BaseReminder,
  careSiteNowUtc: string,
  userId: number
) => {
  const days = moment(reminder.date).diff(moment(careSiteNowUtc), 'days');
  let status: number;
  if (reminder.isCompleted) {
    status = 6;
  } else if (reminder.isNew && reminder.owner!.id === userId && userId !== 0) {
    status = 5;
  } else if (days > 0) {
    status = 4;
  } else {
    status = reminder.importanceId || 0;
  }

  const daysFormatted = (days < 0 ? '+' : '') + Math.abs(days);
  return {
    days: days,
    daysFormatted: daysFormatted,
    status,
    statusColor: mapReminderStatusToIconColor(status),
  };
};

export const getReminderState =
  (careSiteNowUtc: string, userId: number) => (reminder: IReminderStatus) =>
    getReminderStatusAndDays(reminder, careSiteNowUtc, userId);
