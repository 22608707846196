import { TableEditRow } from '@devexpress/dx-react-grid-material-ui';
import { TextField } from '@mui/material';
import React, { ChangeEvent } from 'react';
import { CellComponentStyle } from './CellComponentStyle';
import { ValidationStatusType } from 'components/home/patientdetails/tabscontent/backgroundTab/PatientDetailsBackgroundSubTabs/Tabs/contactTab/contactsGrid';

export interface EditingCellProps extends TableEditRow.CellProps {
  inputProps?: { maxLength: number };
  error?: boolean;
  multiline?: boolean;
  fullwidth?: boolean;
  autoFocus?: boolean;
  required?: boolean;
  validationStatus?: ValidationStatusType[];
  onBlurCustom?: (
    type: string,
    columnName: string,
    inputValue: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onValueChange: (newValue: any) => void
  ) => void;
}

export const EditingCell = (props: EditingCellProps) => {
  const error = props.validationStatus
    ? props.validationStatus.find((x) => x.columnName == props.column.name)
        ?.isError
    : false;
  const helperText = props.validationStatus
    ? props.validationStatus.find((x) => x.columnName == props.column.name)
        ?.validationStatusText
    : false;
  return (
    <TableEditRow.Cell
      {...props}
      onBlur={() => {
        props.onBlurCustom &&
          props.onBlurCustom(
            props.row.type,
            props.column.name,
            props.value,
            props.onValueChange
          );
      }}
      style={{ ...CellComponentStyle, ...{ padding: '0px 24px 0 8px' } }}
    >
      <TextField
        data-testid="TextField"
        value={props.value}
        inputProps={{ ...props.inputProps, 'data-testid': 'InputTextField' }}
        onChange={(event: ChangeEvent<{ value: unknown }>) =>
          props.onValueChange(event.target.value)
        }
        multiline={props.multiline}
        fullWidth={props.fullwidth}
        error={error}
        size="small"
        variant="outlined"
        required={props.required}
        style={{ marginRight: '10px' }}
        helperText={helperText}
      />
    </TableEditRow.Cell>
  );
};
