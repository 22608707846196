import { PatientClaimDto } from 'graphql/graphqlTypes';

export enum claimColumns {
  claimId = 'claimId',
  claimDate = 'claimDate',
  serviceFrom = 'serviceFrom',
  serviceTo = 'serviceTo',
  checkDate = 'checkDate',
  paidAmount = 'paidAmount',
}

export const claimGridColumns = [
  {
    name: claimColumns.claimId,
    title: 'Claim ID',
  },
  {
    name: claimColumns.claimDate,
    title: 'Service Date',
  },
  {
    name: claimColumns.serviceFrom,
    title: 'From Date',
  },
  {
    name: claimColumns.serviceTo,
    title: 'To Date',
  },
  {
    name: claimColumns.checkDate,
    title: 'Check Date',
  },
  {
    name: claimColumns.paidAmount,
    title: 'Paid Amount',
    getCellValue: (row: PatientClaimDto) =>
      row?.paidAmount ? `$ ${row?.paidAmount}` : null,
  },
];
