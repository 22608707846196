import React from 'react';
import { IState } from 'store';
import { useSelector } from 'react-redux';
import ClaimsGrid from 'components/home/claimsList/ClaimsGrid';
import { PatientClaimDto } from 'graphql/graphqlTypes';
import { useGetClaimsQuery } from 'graphql/hooks/getClaims';

const ClaimsTab = () => {
  const patientId = useSelector(
    (state: IState) => state.patientDetails.patientId
  );
  const { data, isFetching } = useGetClaimsQuery({ patientId });

  return (
    <ClaimsGrid
      claims={(data?.getClaims as PatientClaimDto[]) ?? []}
      isFetching={isFetching}
    />
  );
};

export default ClaimsTab;
