import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type SearchClaimsQueryVariables = Types.Exact<{
  request: Types.SearchClaimRequestInput;
}>;

export type SearchClaimsQuery = {
  __typename?: 'EntitiesQuery';
  searchClaims: {
    __typename?: 'PatientClaimDtoSearchResult';
    pageNumber: number;
    pageSize: number;
    totalCount: number;
    rows: Array<{
      __typename?: 'PatientClaimDto';
      id: number;
      claimId?: string | null;
      claimDate?: any | null;
      serviceFrom?: any | null;
      serviceTo?: any | null;
      paidAmount?: any | null;
      claimReceivedDate?: any | null;
      memberResponsibility?: any | null;
      billingProviderName?: string | null;
      billingProviderTitle?: string | null;
      serviceLocationName?: string | null;
      checkDate?: any | null;
      lastModifiedDateTime?: any | null;
      memberBenefitNetwork?: string | null;
      diagnosisCodes?: Array<{
        __typename?: 'MedicationCode';
        id: number;
        code?: string | null;
        shortDescription?: string | null;
      } | null> | null;
      procedureCodes?: Array<{
        __typename?: 'MedicationCode';
        id: number;
        code?: string | null;
        shortDescription?: string | null;
      } | null> | null;
      provider?: {
        __typename?: 'Provider';
        id: number;
        lastName?: string | null;
        firstName?: string | null;
        providerId?: string | null;
        nPI?: string | null;
      } | null;
    }>;
  };
};

export const SearchClaimsDocument = `
    query searchClaims($request: SearchClaimRequestInput!) {
  searchClaims(request: $request) {
    pageNumber
    pageSize
    totalCount
    rows {
      id
      claimId
      claimDate
      serviceFrom
      serviceTo
      paidAmount
      diagnosisCodes {
        id
        code
        shortDescription
      }
      procedureCodes {
        id
        code
        shortDescription
      }
      provider {
        id
        lastName
        firstName
        providerId
        nPI
      }
      claimReceivedDate
      memberResponsibility
      billingProviderName
      billingProviderTitle
      serviceLocationName
      checkDate
      lastModifiedDateTime
      memberBenefitNetwork
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    searchClaims: build.query<SearchClaimsQuery, SearchClaimsQueryVariables>({
      query: (variables) => ({ document: SearchClaimsDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useSearchClaimsQuery, useLazySearchClaimsQuery } =
  injectedRtkApi;
