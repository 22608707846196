import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetSystemValueByNameQueryVariables = Types.Exact<{
  name: Types.Scalars['String'];
}>;

export type GetSystemValueByNameQuery = {
  __typename?: 'EntitiesQuery';
  getSystemValueByName?: string | null;
};

export const GetSystemValueByNameDocument = `
    query getSystemValueByName($name: String!) {
  getSystemValueByName(name: $name)
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getSystemValueByName: build.query<
      GetSystemValueByNameQuery,
      GetSystemValueByNameQueryVariables
    >({
      query: (variables) => ({
        document: GetSystemValueByNameDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetSystemValueByNameQuery,
  useLazyGetSystemValueByNameQuery,
} = injectedRtkApi;
