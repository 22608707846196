import { Box, TextField, Typography } from '@mui/material';
import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  HealthServiceCodesActionModel,
  ILookupValue,
  LookupValue,
  Maybe,
} from 'graphql/graphqlTypes';
import {
  DecisionAction,
  DeterminationDecision,
  HealthServiceAuthorizationType,
} from '../types';
import { getNumberOrNull } from './ServiceRow.helpers';
import { healthServiceDisplayMode } from '../../../HealthServiceDisplayMode';
import { isNumber, isUndefined, toNumber } from 'lodash';
import { isOverallStatus, overallStatus } from '../HealthServiceV2helper';
import ReasonSelect from './ReasonSelect';
import { DecisionButtonGroup } from './DecisionButtonGroup';
import {
  OverallStatusBox,
  OverallStyledBox,
  OverallStyledTypography,
  OverallSummaryBox,
  OverallTypographyDenial,
  StyledReasonBox,
  OverallRNReview,
} from '@PerformanceClincial/checklist-ui';

export interface ISummaryState {
  determinationReasons: { [key: string]: ILookupValue[] };
  unitTypes: ILookupValue[];
  hsModel: HealthServiceCodesActionModel;
  episodeDecision: DeterminationDecision;
  onChange?: (state: HealthServiceCodesActionModel) => void;
  hsAuthorizationType: number;
  hSDisplayMode: number;
  defaultUnitType: ILookupValue | undefined;
  actionOnDecision: (decision: DeterminationDecision) => void;
  actionOnGroup: (
    decision: DeterminationDecision,
    reason: LookupValue | null
  ) => void;
  canPending?: boolean;
  canApprove?: boolean;
  canDenied?: boolean;
  canPartial?: boolean;
  readOnly?: boolean;
  readOnlyView?: boolean;
}

export const HsManualSummary = (props: ISummaryState) => {
  const {
    determinationReasons,
    hsModel,
    episodeDecision,
    onChange,
    hsAuthorizationType,
    hSDisplayMode,
    actionOnGroup,
    canPending,
    canApprove,
    canDenied,
    canPartial,
    readOnly,
    readOnlyView,
  } = props;

  const isOverallStatusApproved = isOverallStatus(hsModel, 'Approved');
  const overallStatusVal = overallStatus(hsModel);

  const [groupDecision, updateGroupDecision] = useState<DeterminationDecision>(
    hsAuthorizationType === HealthServiceAuthorizationType.Episode
      ? (hsModel?.healthServiceCodesActionValues[0]?.healthServiceActionValue
          ?.determinationDecision as DeterminationDecision) ??
          DecisionAction.nonSet
      : (hsModel.overallDeterminationDecision as DeterminationDecision) ??
          DecisionAction.nonSet
  );

  const isReasonReadOnly =
    readOnly || (!canApprove && !canDenied && !canPending && !canPartial);

  const modelReason =
    hsModel?.healthServiceCodesActionValues?.length > 0
      ? hsModel?.healthServiceCodesActionValues[0]?.healthServiceActionValue
          ?.determinationReason ?? null
      : null;

  const [determinationReason, updateDeterminationReason] =
    useState<LookupValue | null>(modelReason);

  const getNumber = (num: Maybe<number> | undefined) => {
    return isNumber(num) ? toNumber(num) : 0;
  };

  useEffect(() => {
    updateDeterminationReason(modelReason);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelReason]);

  const getDeniedUnits = (
    totalReqUnits: Maybe<number> | undefined,
    totalAprUnits: Maybe<number> | undefined
  ) => {
    const totalReq = getNumber(totalReqUnits);
    const totalApr = getNumber(totalAprUnits);
    if (totalApr <= totalReq) {
      return totalReq - totalApr;
    }
    return 0;
  };

  const handleTotalApprUnitsChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newModel: HealthServiceCodesActionModel = {
      ...hsModel,
    };
    newModel.totalApprUnits = getNumberOrNull(e.target.value);
    newModel.totalDeniedUnits = getDeniedUnits(
      newModel.totalReqUnits,
      newModel.totalApprUnits
    );

    if (!isUndefined(onChange)) {
      onChange(newModel);
    }
  };

  const onUpdateDeterminationReason = (reason: LookupValue | null) => {
    updateDeterminationReason(reason);
    if (
      hsAuthorizationType === HealthServiceAuthorizationType.Episode &&
      reason !== null
    ) {
      actionOnGroup(groupDecision, reason);
    }
  };

  return (
    <OverallSummaryBox>
      <OverallStatusBox>
        <Typography variant="body2">Overall Status:</Typography>
      </OverallStatusBox>
      {hsAuthorizationType === HealthServiceAuthorizationType.Episode && (
        <>
          <Box display="flex" alignItems="baseline" gap="20px">
            <DecisionButtonGroup
              updateGroupDecision={updateGroupDecision}
              groupDecision={groupDecision}
              readOnly={readOnly}
              pendingOnly={canPending}
              canApprove={canApprove}
              canDenied={canDenied}
              canPartial={canPartial}
            />
            <StyledReasonBox display="flow" alignItems="center">
              <OverallStyledBox>
                <OverallStyledTypography>Reason:</OverallStyledTypography>
                <ReasonSelect
                  showLabel={false}
                  isRequired={false}
                  determinationReasons={determinationReasons}
                  groupDecision={groupDecision}
                  width="266px"
                  readOnly={isReasonReadOnly}
                  value={determinationReason}
                  updateValue={onUpdateDeterminationReason}
                />
              </OverallStyledBox>
              <OverallStyledBox>
                <OverallStyledTypography>
                  Approved Units:
                </OverallStyledTypography>
                {episodeDecision === 'Partial' &&
                hSDisplayMode === healthServiceDisplayMode.manualReview &&
                hsAuthorizationType ===
                  HealthServiceAuthorizationType.Episode &&
                !readOnlyView ? (
                  <>
                    <TextField
                      type="tel"
                      size="medium"
                      variant="outlined"
                      value={hsModel?.totalApprUnits ?? 0}
                      onChange={handleTotalApprUnitsChange}
                      style={{ width: '68px' }}
                      data-testid="hs-apprunits"
                    />
                    <OverallTypographyDenial>Denial:</OverallTypographyDenial>
                    <Typography variant="body2">
                      {hsModel?.totalDeniedUnits}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography variant="body2">
                      {hsModel?.totalApprUnits}
                    </Typography>
                    {episodeDecision === 'Partial' && (
                      <>
                        <OverallTypographyDenial>
                          Denial:
                        </OverallTypographyDenial>
                        <Typography variant="body2">
                          {hsModel?.totalDeniedUnits}
                        </Typography>
                      </>
                    )}
                  </>
                )}
              </OverallStyledBox>
            </StyledReasonBox>
          </Box>
        </>
      )}
      <OverallStyledBox marginTop="12px" gap="18px">
        {hsAuthorizationType !== HealthServiceAuthorizationType.Episode && (
          <>
            <OverallRNReview
              isOverallStatusApproved={isOverallStatusApproved}
              overallStatus={overallStatusVal}
              isOverallStatus={true}
            />
            <OverallStyledBox>
              <OverallStyledTypography variant="body2">
                Reason:
              </OverallStyledTypography>
              <ReasonSelect
                showLabel={false}
                isRequired={false}
                determinationReasons={determinationReasons}
                groupDecision={episodeDecision}
                width="236px"
                readOnly={false}
                value={determinationReason}
                updateValue={() => {
                  return;
                }}
              />
            </OverallStyledBox>
            <OverallStyledBox>
              <OverallStyledTypography>Approved Units:</OverallStyledTypography>
              <Typography variant="body2">{hsModel?.totalApprUnits}</Typography>
            </OverallStyledBox>
          </>
        )}
      </OverallStyledBox>
    </OverallSummaryBox>
  );
};
