import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type EpisodeAutoAssignMutationVariables = Types.Exact<{
  autoAssignModel: Types.AutoAssignInputModelInput;
}>;

export type EpisodeAutoAssignMutation = {
  __typename?: 'Mutation';
  episodeAutoAssign?: Types.AutoAssignStatus | null;
};

export const EpisodeAutoAssignDocument = `
    mutation episodeAutoAssign($autoAssignModel: AutoAssignInputModelInput!) {
  episodeAutoAssign(autoAssignModel: $autoAssignModel)
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    episodeAutoAssign: build.mutation<
      EpisodeAutoAssignMutation,
      EpisodeAutoAssignMutationVariables
    >({
      query: (variables) => ({
        document: EpisodeAutoAssignDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useEpisodeAutoAssignMutation } = injectedRtkApi;
