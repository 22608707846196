import { AxiosResponse } from 'axios';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import DOMPurify from 'dompurify';
import { showErrorPopup } from 'store/errorPopup/errorPopupSlice';
import { base64ToByteArray } from 'util/helpers/base64Helper';
import axiosGitHubGraphQL from 'util/axiosHttp';

const getDownloadFileName = <TData = unknown>(
  response: AxiosResponse,
  responseType?: 'blob',
  getFileName?: (data: TData) => string | null
): string | null => {
  let fileName: string | null = null;
  if (getFileName) {
    fileName = getFileName(response.data);
  } else if (responseType === 'blob') {
    fileName = 'attachments.zip';
  } else {
    fileName = response.data.fileName;
  }

  return fileName;
};

function getValidMimeType(srcType: string): string | undefined {
  let validType;
  const mimeTypes = ['application/zip'];
  for (const mimeType of mimeTypes) {
    if (srcType === mimeType) {
      validType = mimeType;
      break;
    }
  }
  return validType;
}

const useDownloadFile = () => {
  const dispatch = useDispatch();

  const getDownloadData = (
    response: AxiosResponse,
    responseType?: 'blob'
  ): Blob => {
    if (responseType === 'blob') {
      return response.data;
    }

    const validType = getValidMimeType(response.data.mimeType);
    if (!validType) {
      throw new Error('Invalid MIME type');
    }

    return new Blob([base64ToByteArray(response.data.content)], {
      type: validType,
    });
  };

  const createDownloadUrl = (downloadData: Blob): string => {
    const url = window.URL || window.webkitURL;
    const downloadUrl = url.createObjectURL(downloadData);
    const validUrl = new URL(downloadUrl);

    if (validUrl.protocol !== 'blob:') {
      throw new Error('Invalid URL protocol');
    }

    return validUrl.href;
  };

  const downloadFile = <TData>(
    downloadUrl: string,
    response: AxiosResponse,
    responseType: 'blob' | undefined,
    getFileName?: (data: TData) => string | null
  ) => {
    const link = document.createElement('a');
    link.href = encodeURI(downloadUrl);

    let fileName = getDownloadFileName(response, responseType, getFileName);
    if (fileName) {
      fileName = fileName.replace(/[<>:"/\\|?*]+/g, '_');
      fileName = DOMPurify.sanitize(fileName);
      link.download = fileName;
    }

    document.body.appendChild(link);
    link.click();

    setTimeout(() => {
      const url = window.URL || window.webkitURL;
      url.revokeObjectURL(downloadUrl);
      link?.parentNode?.removeChild(link);
    }, 100);
  };

  return useCallback(
    <TParams = object, TData = unknown>(
      urlPath: string,
      requestParams: TParams,
      responseType?: 'blob',
      getFileName?: (data: TData) => string | null
    ) => {
      const handleDownloadError = () => {
        dispatch(
          showErrorPopup({
            message: 'Failed to download file!',
          })
        );
      };

      const processFileDownload = <TData>(
        response: AxiosResponse,
        responseType: 'blob' | undefined,
        getFileName?: (data: TData) => string | null
      ) => {
        try {
          const downloadData = getDownloadData(response, responseType);
          const downloadUrl = createDownloadUrl(downloadData);

          downloadFile<TData>(downloadUrl, response, responseType, getFileName);
        } catch (error) {
          dispatch(
            showErrorPopup({
              message: 'Download failed: Invalid URL or protocol.',
            })
          );
        }
      };

      axiosGitHubGraphQL
        .post(
          urlPath,
          requestParams,
          responseType ? { responseType } : undefined
        )
        .then(
          (response) =>
            processFileDownload<TData>(response, responseType, getFileName),
          handleDownloadError
        );
    },
    [dispatch]
  );
};

export default useDownloadFile;
