import React, { useEffect, useState } from 'react';
import { Box, IconButton } from '@mui/material';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import styled from 'styled-components';
import { useGetSystemValueByNameQuery } from 'graphql/hooks/getSystemValueByName';

const BannerContainer = styled(Box)`
  background-color: ${COLORS.YELLOW20};
  color: ${COLORS.RED100};
  padding: 10px 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  width: 100%;
  box-sizing: border-box;
`;

const Banner = () => {
  const [announcement, setAnnouncement] = useState('');

  const closeBanner = () => {
    setAnnouncement('');
  };

  const { data, isLoading, isError } = useGetSystemValueByNameQuery({
    name: 'SystemAnnouncement',
  });

  useEffect(() => {
    if (!isLoading && !isError && data) {
      const msg = data?.getSystemValueByName ?? '';
      setAnnouncement(msg);
    }
  }, [isLoading, isError, data]);

  if (!announcement) {
    return null;
  }

  return (
    <BannerContainer>
      <Box display="flex" alignItems="center" gap="8px">
        <Icon size={20} icon={ICONS.warning_filled} color={COLORS.YELLOW100} />
        <span>{announcement}</span>
      </Box>
      <IconButton onClick={closeBanner}>
        <Icon size={20} icon={ICONS.Close} color={COLORS.RED100} />
      </IconButton>
    </BannerContainer>
  );
};

export default Banner;
