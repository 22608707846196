import { claimColumns } from './claimGridColumns';

export const ClaimColumnExtension = [
  {
    columnName: claimColumns.claimDate,
    editingEnabled: false,
    wordWrapEnabled: true,
    sortingEnabled: true,
  },
  {
    columnName: claimColumns.serviceFrom,
    editingEnabled: false,
    wordWrapEnabled: true,
    sortingEnabled: true,
  },
  {
    columnName: claimColumns.serviceTo,
    editingEnabled: false,
    wordWrapEnabled: true,
    sortingEnabled: true,
  },
  {
    columnName: claimColumns.checkDate,
    editingEnabled: false,
    wordWrapEnabled: true,
    sortingEnabled: true,
  },
  {
    columnName: claimColumns.paidAmount,
    editingEnabled: false,
    wordWrapEnabled: true,
    sortingEnabled: true,
  },
];
