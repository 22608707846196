import React from 'react';
import { isUndefined } from 'lodash';
import { useSelector } from 'react-redux';
import { Box, Grid, TextField, styled } from '@mui/material';
import { IState } from 'store';
import { ChecklistStorageType } from 'store/actions/types';
import {
  AssociatedClaimAttributes,
  MedicationCode,
  MedicationCodeType,
  Provider,
} from 'graphql/graphqlTypes';
import { COLORS } from 'consts/styles';
import { validateRequired } from 'util/validationUtils';
import StyledLink from 'components/actionlink/StyledLink';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';
import MedicationCodeSearch from 'components/autocomplete/medicationCodeSearch';
import ProviderSearch from 'components/autocomplete/providerSearch';
import {
  AssociatedClaimAction,
  AssociatedClaimAttributeNames,
  getClaimValue,
  transformMedicationCodes,
} from './AssociatedClaim.helper';
import DatePicker from '../HealthService/Components/DatePicker';
import { formatDate } from '../HealthService/Components/ServiceRow.helpers';
import {
  DATETIME_SECONDS_FORMAT,
  MOMENT_TIME_SECONDS_FORMAT,
} from 'components/constants';
import { formatDateTime } from 'util/helpers/dateTimeHelpers';
import TimeComponent from 'components/TimeComponent';

export const StyledGrid = styled(Grid)({
  marginBottom: '20px',
  marginTop: '5px',
  paddingBottom: '20px',
  backgroundColor: COLORS.GREY10,
  alignItems: 'center',
  marginLeft: '0',
  width: '100%',
});

export interface IAssociatedClaimRowProps {
  claimAttributes: AssociatedClaimAttributes[];
  claimActionValue: AssociatedClaimAction;
  onChange?: (claim: AssociatedClaimAction) => void;
  onDelete?: (claim: AssociatedClaimAction) => void;
}

const AssociatedClaimRow = (props: IAssociatedClaimRowProps) => {
  const { claimAttributes, claimActionValue, onChange, onDelete } = props;
  const readOnly = claimActionValue.id === 0 && onChange;
  const patientId = Number(
    useSelector(
      (state: IState) =>
        state.checklist.documentsState[ChecklistStorageType.MOZART].patientId
    ) ?? 0
  );

  const handleChange = (value: unknown, attributeName: string) => {
    if (!isUndefined(onChange)) {
      const claim = claimActionValue;
      switch (attributeName) {
        case AssociatedClaimAttributeNames.ClaimId: {
          claim.claimId = value as string;
          break;
        }
        case AssociatedClaimAttributeNames.AmountPaid: {
          claim.paidAmount = value;
          break;
        }
        case AssociatedClaimAttributeNames.ClaimDate: {
          claim.claimDate = value;
          break;
        }
        case AssociatedClaimAttributeNames.ServiceFrom: {
          claim.serviceFrom = value;
          break;
        }
        case AssociatedClaimAttributeNames.ServiceTo: {
          claim.serviceTo = value;
          break;
        }
        case AssociatedClaimAttributeNames.Provider: {
          const provider = value as Provider;
          claim.provider = provider;
          claim.providerId = provider?.id;
          break;
        }
        case AssociatedClaimAttributeNames.ClaimReceivedDate: {
          claim.claimReceivedDate = value;
          break;
        }
        case AssociatedClaimAttributeNames.MemberResponsibility: {
          claim.memberResponsibility = value;
          break;
        }
        case AssociatedClaimAttributeNames.BillingProviderName: {
          claim.billingProviderName = value as string;
          break;
        }
        case AssociatedClaimAttributeNames.BillingProviderTitle: {
          claim.billingProviderTitle = value as string;
          break;
        }
        case AssociatedClaimAttributeNames.ServiceLocationName: {
          claim.serviceLocationName = value as string;
          break;
        }
        case AssociatedClaimAttributeNames.CheckDate: {
          claim.checkDate = value;
          break;
        }
        case AssociatedClaimAttributeNames.LastModifiedDateTime: {
          claim.lastModifiedDateTime = value;
          break;
        }
        case AssociatedClaimAttributeNames.MemberBenefitNetwork: {
          claim.memberBenefitNetwork = value as string;
          break;
        }
        default:
          break;
      }
      onChange(claim);
    }
  };

  const handleMedicationCodeChange = (
    value: MedicationCode[] | null,
    type: MedicationCodeType
  ) => {
    if (!isUndefined(onChange)) {
      const claim = claimActionValue;
      if (type === MedicationCodeType.Icd_10Cm) {
        claim.diagnosisCodes = value;
      }
      if (type === MedicationCodeType.CptHcpcs) {
        claim.procedureCodes = value;
      }
      onChange(claim);
    }
  };

  const getDateTime = (value: Date): Date => {
    return new Date(formatDateTime(value, DATETIME_SECONDS_FORMAT));
  };

  const render = (attributeName: string) => {
    const claim = claimActionValue;
    switch (attributeName) {
      case AssociatedClaimAttributeNames.ClaimId: {
        const hasError =
          validateRequired(claim?.claimId, true).hasError ?? false;
        return (
          <TextField
            type="text"
            size="medium"
            variant="outlined"
            name="claimId"
            value={claim?.claimId}
            onChange={(event) =>
              handleChange(event.target.value, attributeName)
            }
            required
            error={hasError}
          />
        );
      }
      case AssociatedClaimAttributeNames.AmountPaid:
        return (
          <TextField
            type="number"
            size="medium"
            variant="outlined"
            name="paidAmount"
            value={claim?.paidAmount}
            onChange={(event) =>
              handleChange(event.target.value, attributeName)
            }
          />
        );
      case AssociatedClaimAttributeNames.ClaimDate:
        return (
          <DatePicker
            value={formatDate(claim.claimDate)}
            onChange={(date) => handleChange(date, attributeName)}
            error={false}
          />
        );
      case AssociatedClaimAttributeNames.ServiceFrom:
        return (
          <DatePicker
            value={formatDate(claim.serviceFrom)}
            onChange={(date) => handleChange(date, attributeName)}
            error={false}
          />
        );
      case AssociatedClaimAttributeNames.ServiceTo:
        return (
          <DatePicker
            value={formatDate(claim.serviceTo)}
            onChange={(date) => handleChange(date, attributeName)}
            error={false}
          />
        );
      case AssociatedClaimAttributeNames.DiagnosisCode: {
        return (
          <MedicationCodeSearch
            type={MedicationCodeType.Icd_10Cm}
            value={transformMedicationCodes(
              claim?.diagnosisCodes?.filter(
                (code): code is MedicationCode => code !== null
              ) || null
            )}
            onChange={(value) => {
              handleMedicationCodeChange(
                value as MedicationCode[],
                MedicationCodeType.Icd_10Cm
              );
            }}
            isMultiple={true}
          />
        );
      }
      case AssociatedClaimAttributeNames.ProcedureCode:
        return (
          <MedicationCodeSearch
            type={MedicationCodeType.CptHcpcs}
            value={
              typeof claim.procedureCodes === 'string'
                ? claim.procedureCodes
                : transformMedicationCodes(
                    claim?.procedureCodes?.filter(
                      (code): code is MedicationCode => code !== null
                    ) || null
                  )
            }
            onChange={(value) => {
              handleMedicationCodeChange(
                value as MedicationCode[],
                MedicationCodeType.CptHcpcs
              );
            }}
            isMultiple={true}
          />
        );
      case AssociatedClaimAttributeNames.Provider:
        return (
          <ProviderSearch
            patientId={patientId}
            value={claim.provider as Provider}
            onChange={(value) => handleChange(value, attributeName)}
          />
        );
      case AssociatedClaimAttributeNames.ClaimReceivedDate: {
        const hasError =
          validateRequired(claim?.claimReceivedDate, true).hasError ?? false;
        return (
          <DatePicker
            value={formatDate(claim.claimReceivedDate)}
            onChange={(date) => handleChange(date, attributeName)}
            error={hasError}
          />
        );
      }
      case AssociatedClaimAttributeNames.MemberResponsibility:
        return (
          <TextField
            type="number"
            size="medium"
            variant="outlined"
            name="memberResponsibility"
            value={claim?.memberResponsibility}
            onChange={(event) =>
              handleChange(event.target.value, attributeName)
            }
          />
        );
      case AssociatedClaimAttributeNames.BillingProviderName:
        return (
          <TextField
            type="text"
            size="medium"
            variant="outlined"
            name="billingProviderName"
            value={claim?.billingProviderName}
            onChange={(event) =>
              handleChange(event.target.value, attributeName)
            }
          />
        );
      case AssociatedClaimAttributeNames.BillingProviderTitle:
        return (
          <TextField
            type="text"
            size="medium"
            variant="outlined"
            name="billingProviderTitle"
            value={claim?.billingProviderTitle}
            onChange={(event) =>
              handleChange(event.target.value, attributeName)
            }
          />
        );
      case AssociatedClaimAttributeNames.ServiceLocationName:
        return (
          <TextField
            type="text"
            size="medium"
            variant="outlined"
            name="serviceLocationName"
            value={claim?.serviceLocationName}
            onChange={(event) =>
              handleChange(event.target.value, attributeName)
            }
          />
        );
      case AssociatedClaimAttributeNames.CheckDate: {
        const hasError =
          validateRequired(claim?.checkDate, true).hasError ?? false;
        return (
          <DatePicker
            value={formatDate(claim.checkDate)}
            onChange={(date) => handleChange(date, attributeName)}
            error={hasError}
          />
        );
      }
      case AssociatedClaimAttributeNames.LastModifiedDateTime: {
        const hasError =
          validateRequired(claim?.lastModifiedDateTime, true).hasError ?? false;

        return (
          <>
            <DatePicker
              value={formatDate(claim.lastModifiedDateTime)}
              onChange={(date) => handleChange(date, attributeName)}
              error={hasError}
            />
            <TimeComponent
              value={getDateTime(claim.lastModifiedDateTime)}
              testId="time-editor"
              onChange={(date) => handleChange(date, attributeName)}
              timeFormat={MOMENT_TIME_SECONDS_FORMAT}
              placeholder="hh:mm:ss"
              required={false}
            />
          </>
        );
      }
      case AssociatedClaimAttributeNames.MemberBenefitNetwork:
        return (
          <TextField
            type="text"
            size="medium"
            variant="outlined"
            name="memberBenefitNetwork"
            value={claim?.memberBenefitNetwork}
            onChange={(event) =>
              handleChange(event.target.value, attributeName)
            }
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <StyledGrid container spacing={2} rowSpacing={3}>
      {claimAttributes.map((attribute, index) => {
        return (
          <React.Fragment
            key={`${claimActionValue.uniqueId}-${attribute.name}`}
          >
            <Grid item xs={12} sm={2}>
              {attribute.name}
            </Grid>
            <Grid item xs={12} sm={9}>
              {readOnly
                ? render(attribute.value)
                : getClaimValue(claimActionValue, attribute.value)}
            </Grid>
            <Grid item xs={12} sm={1}>
              {onDelete && index === 0 && (
                <Box display="flex" justifyContent="flex-end" pr="16px">
                  <CustomTooltip title="Delete">
                    <StyledLink onClick={() => onDelete(claimActionValue)}>
                      Delete
                    </StyledLink>
                  </CustomTooltip>
                </Box>
              )}
            </Grid>
          </React.Fragment>
        );
      })}
    </StyledGrid>
  );
};
export default AssociatedClaimRow;
