import axios, { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import { Cookies } from 'react-cookie';
import { clearAuthUser } from './authUtils';
import { errorHandler, errorHandlerUrl } from 'store/errorHandler';

const axiosGitHubGraphQL = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/`,
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    'SYM-UI': 'new',
  },
  withCredentials: true,
});

export const newComposerApiClient = axios.create({
  baseURL: `${process.env.REACT_APP_COMPOSER_API_URL}/api/composerapi/v1/`,
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
});

export function addAuth(config: AxiosRequestConfig) {
  const cookies = new Cookies();
  const token = cookies.get('.AspNetCore.Application.Id');
  if (token) {
    if (!config.headers) {
      config.headers = {} as AxiosRequestHeaders;
    }
    config.headers['Authorization'] = `Bearer ${token}`;
  }
}

export const fetchCsrfToken = async (): Promise<string | null> => {
  const config = {
    baseURL: `${process.env.REACT_APP_API_URL}/api`,
    withCredentials: true,
  };
  addAuth(config);
  const response = await axios.get('/antiforgery-token', config);
  return response.data.token as string;
};

axiosGitHubGraphQL.interceptors.request.use(
  async (config) => {
    addAuth(config);
    const csrfToken = await fetchCsrfToken();
    if (csrfToken) {
      config.headers['X-XSRF-TOKEN'] = csrfToken;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export interface IResponseLocation {
  line: number;
  column: number;
}

export interface IResponseExtension {
  code: string;
}

export interface IResponseError {
  message: string;
  path: string[];
  locations: IResponseLocation[];
  extensions: IResponseExtension;
}

axiosGitHubGraphQL.interceptors.response.use(
  (response) => {
    if (response.data.errors) {
      response.data.errors.forEach((error: IResponseError) => {
        if (error.extensions?.code === 'AUTH_NOT_AUTHORIZED') {
          clearAuthUser();
          window.location.href = '/';
        }
      });
    }
    return response;
  },
  (error) => {
    if (error?.config?.url !== errorHandlerUrl) {
      errorHandler(
        `POST request: ${error?.config?.url}`,
        error.message,
        error.stack
      );
    }

    if (error?.response?.status === 401) {
      clearAuthUser();
      window.location.href = '/';
    } else {
      return Promise.reject(error);
    }
  }
);

export default axiosGitHubGraphQL;
