import React, { ReactElement, useState } from 'react';

import {
  Grid,
  Table,
  TableEditColumn,
  TableEditRow,
} from '@devexpress/dx-react-grid-material-ui';

import {
  ChangeSet,
  Column,
  EditingState,
  FilteringState,
  IntegratedSorting,
} from '@devexpress/dx-react-grid';
import {
  GridEditActions,
  StubCellComponent,
  SymphonyTable,
  SymphonyTableHeaderRow,
} from 'components/gridFormatters';
import { Box, Button, Typography } from '@mui/material';
import { Getter } from '@devexpress/dx-react-core';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import {
  AddressDto,
  EmailDto,
  PhoneDto,
  TextAttributeType,
} from 'graphql/graphqlTypes';
import {
  EditComponent,
  EditComponentValidationProps,
} from './cells/EditComponent';
import { getVisibleRows } from './contacts.helpers';
import { rearrangeColumnsEditLast } from 'components/home/attachmentsList/attachments.helpers';
import { omit } from 'lodash';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';
import { PatientAttributeType } from './contactTab';
import { columnToAttribute } from './address';
import { TEXT_FIELD_TYPE, validateTextField } from 'util/validationUtils';
import { applyUpdateMaskCriteria } from 'util/formatUtils';

type ColumnExtension =
  | EditingState.ColumnExtension
  | FilteringState.ColumnExtension
  | IntegratedSorting.ColumnExtension
  | Table.ColumnExtension;

export interface IGridProps<T extends AddressDto | EmailDto | PhoneDto> {
  title: string;
  itemName: string;
  getRowId: (item: T) => string;
  rows: T[];
  commitChanges: (changeSet: ChangeSet) => void;
  columns: Column[];
  columnExtensions?: Array<ColumnExtension>;
  labelCell: React.ComponentType<Table.DataCellProps>;
  editCell: (
    props: EditCellValidationProps
  ) => ReactElement<EditCellValidationProps>;
  editEnabled: boolean;
  setEditEnabled: (status: boolean) => void;
  isExternalDataReadOnly: boolean;
  isSystemUser: boolean;
  attributeTypes?: PatientAttributeType[];
}

export interface ValidationStatusType {
  columnName: string;
  isError: boolean;
  validationStatusText: string;
}

export interface EditCellValidationProps extends TableEditRow.CellProps {
  setErrors: (status: boolean) => void;
  validationStatus?: ValidationStatusType[];
  onBlurCustom?: (
    type: string,
    columnName: string,
    inputValue: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onValueChange: (newValue: any) => void
  ) => void;
}

const MainColumns = ['Main', 'Cell', 'Home'];

const ContactsGrid = <T extends AddressDto | EmailDto | PhoneDto>(
  props: IGridProps<T>
) => {
  const {
    title,
    itemName,
    getRowId,
    rows,
    columns,
    columnExtensions,
    commitChanges,
    labelCell,
    editCell,
    editEnabled,
    setEditEnabled,
    isExternalDataReadOnly,
    isSystemUser,
    attributeTypes,
  } = props;

  const [validationStatus, setValidationStatus] = useState<
    ValidationStatusType[]
  >([]);
  const [editingRowIds, setEditingRowIds] = useState<string[]>([]);
  const [errors, setErrors] = useState(false);

  const visibleRows = getVisibleRows(rows);
  const [visible, setVisible] = useState(visibleRows);
  const allVisible = Object.values(omit(visible, MainColumns)).every(Boolean);

  const addAlternative = () => {
    const invisible = Object.keys(visible)
      .filter((x) => !MainColumns.includes(x))
      .find((k) => !visible[k]);
    if (!invisible) {
      return;
    }
    visible[invisible] = true;
    setVisible({ ...visible });
    setEditingRowIds([invisible]);
    setEditEnabled(false);
  };

  const commit = (changes: ChangeSet) => {
    const { deleted } = changes;
    if (deleted) {
      visible[deleted[0]] = false;
      setVisible({ ...visible });
    }
    commitChanges(changes);
  };

  const onBlurCustom = (
    type: string,
    columnName: string,
    inputValue: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onValueChange: (newValue: any) => void
  ) => {
    const textAttribute = getTextAttribute(type, columnName);
    const formattedValue = applyUpdateMaskCriteria(inputValue, textAttribute);
    handleValidation(type, columnName, formattedValue, textAttribute);
    onValueChange(formattedValue);
  };

  const getTextAttribute = (type: string, columnName: string) => {
    const attributeType =
      columnToAttribute[type as keyof typeof columnToAttribute];
    const attribute = attributeType[columnName as keyof typeof attributeType];

    return attributeTypes?.find((x) => x.name == attribute)?.textAttribute;
  };

  const handleValidation = (
    type: string,
    columnName: string,
    inputValue: string,
    textAttribute: TextAttributeType | null | undefined
  ) => {
    const currentStatus = validationStatus.some(
      (x) => x.columnName == columnName
    );
    const validationStatusNew = validationStatus.filter(
      (x) => x.columnName != columnName
    );

    const result = validateTextField(inputValue, {
      type: TEXT_FIELD_TYPE.TEXT,
      maxLen: textAttribute?.maxLength ?? 0,
      minLen: undefined,
      required: textAttribute?.required ?? false,
      maxVal: undefined,
      minVal: undefined,
      textAttribute: textAttribute,
    });

    if (result.hasError) {
      setValidationStatus([
        ...validationStatusNew,
        {
          columnName: columnName,
          isError: result.hasError,
          validationStatusText: result.message ?? '',
        },
      ]);
      setErrors(true);
      return;
    }

    if (currentStatus) {
      setValidationStatus(validationStatusNew);
    }

    if (validationStatusNew.length == 0 && errors) {
      setErrors(false);
    } else if (validationStatusNew.length > 0 && !errors) {
      setErrors(true);
    }
  };

  const editColumn = (props: EditComponentValidationProps) => (
    <EditComponent {...props} />
  );

  return (
    <Box mb="32px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb="16px"
      >
        <Typography variant="subtitle1">{title}</Typography>
        <CustomTooltip title={`Only one alternative ${itemName} can be added`}>
          <div>
            <Button
              onClick={addAlternative}
              color="primary"
              size="small"
              variant="contained"
              startIcon={
                <Icon icon={ICONS.Add} size="14" color={COLORS.WHITE} />
              }
              disabled={allVisible || !editEnabled}
            >
              Add Alternative
            </Button>
          </div>
        </CustomTooltip>
      </Box>
      <Grid
        rows={rows.filter((x) => visible[getRowId(x)])}
        columns={columns}
        getRowId={getRowId}
      >
        <EditingState
          onCommitChanges={commit}
          columnExtensions={columnExtensions ?? []}
          editingRowIds={editingRowIds}
          onEditingRowIdsChange={(curEditingRowIds: Array<number | string>) => {
            setEditingRowIds(curEditingRowIds as string[]);
            setEditEnabled(curEditingRowIds.length === 0);
          }}
        />
        <SymphonyTable
          cellComponent={labelCell}
          columnExtensions={columnExtensions ?? []}
        />
        <SymphonyTableHeaderRow />
        <TableEditRow
          cellComponent={(cellProps) => {
            return editCell({
              ...cellProps,
              setErrors,
              validationStatus,
              onBlurCustom,
            });
          }}
        />

        <TableEditColumn
          showEditCommand
          showDeleteCommand
          cellComponent={(cellProps) =>
            editColumn({
              ...cellProps,
              isSystemUser,
              isReadOnly: isExternalDataReadOnly,
            })
          }
          headerCellComponent={StubCellComponent}
          commandComponent={(cmdProps) => (
            <GridEditActions
              {...cmdProps}
              editEnabled={editEnabled}
              errors={errors}
              itemName={itemName}
            />
          )}
          width="180px"
        />
        <Getter name="tableColumns" computed={rearrangeColumnsEditLast} />
      </Grid>
    </Box>
  );
};

export default ContactsGrid;
