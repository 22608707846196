import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { IState } from 'store';
import { ICONS } from 'components/icon';
import { ActionButton } from '.';
import { ILetter, IMessageState } from '..';
import { useMutationEffect } from 'features/letters/hooks';
import { useSendToPrintShopMutation } from 'graphql/hooks/sendToPrintShop';
import { useOnLetterPrintMutation } from 'graphql/hooks/onLetterPrint';
import styled from 'styled-components';
import AttachFilesDialog from 'features/letters/attachFilesDialog';
import { Divider } from '@mui/material';
import { hasUserPrivilege } from 'util/helpers/privilegeHelper';
import { Privilege } from 'store/roles/types';
import { FaxParameters, Maybe } from 'graphql/graphqlTypes';
import { useMergeAttachmentsMutation } from 'graphql/hooks/mergeAttachments';
import Loader from 'components/loader';

const StyledButtonsWrapper = styled.div`
  margin-top: auto;
  display: flex;
  flex-wrap: wrap;
`;

const printShopSuccess = {
  active: true,
  type: 'success',
  title: 'Sent to print shop',
  text: 'Letters sent to print shop',
} as IMessageState;

const printShopError = {
  active: true,
  type: 'error',
  title: 'Error',
  text: 'An unexpected error occurred when sending to print shop',
} as IMessageState;

const printSuccess = {
  active: true,
  type: 'success',
  title: 'Success',
  text: 'Letter templates printed',
} as IMessageState;

const printError = {
  active: true,
  type: 'error',
  title: 'Error',
  text: 'An unexpected error occurred when printing',
} as IMessageState;

const getAttachmentSuccessMessage = (
  fileCount: number,
  replaced: number,
  added: number
) => {
  const replacedText = replaced
    ? `${fileCount} file(s) replaced on ${replaced} letter(s)`
    : undefined;
  const addedText = added
    ? `${fileCount} file(s) attached to ${added} letter(s)`
    : undefined;

  const text = [replacedText, addedText].filter(Boolean).join('.');

  return {
    active: true,
    type: 'success',
    title: 'File attached to letters',
    text: text,
  } as IMessageState;
};

const attachmentError = {
  active: true,
  type: 'error',
  title: 'Error',
  text: 'File could not be attached',
} as IMessageState;

const StyledDivider = styled(Divider)`
  height: 30px;
  margin-top: 8px;
  margin-right: 16px;
`;

export interface ILetterActionsProps {
  letters: ILetter[];
  isPrintShopActive: boolean;
  patientId: number;
  episodeId: number;
  setIsSelectedView: (isSelectedView: boolean) => void;
  setMessage: (message: IMessageState) => void;
  setLoading: (loading: boolean) => void;
  setLetters: (letters: ILetter[]) => void;
  setView: (view: 'letters' | 'fax') => void;
  setPreview: (preview: string | null) => void;
  setActionExecuted: (actionExecuted: boolean) => void;
  printIframe: () => void;
  fetchPreview: () => void;
  isSelectedView: boolean;
  faxParameter: Maybe<FaxParameters> | undefined;
}

export const LetterActions = ({
  letters,
  isPrintShopActive,
  setMessage,
  patientId,
  episodeId,
  setLoading,
  setLetters,
  setView,
  setPreview,
  printIframe,
  fetchPreview,
  setIsSelectedView,
  setActionExecuted,
  isSelectedView,
  faxParameter,
}: ILetterActionsProps) => {
  const selectedAttachmentIds = useSelector(
    (state: IState) =>
      state.patientDetailsAttachment?.selectedAttachmentIds ?? []
  );
  const uploadedFiles = useSelector(
    (state: IState) => state.fileUpload.uploadedFiles
  );
  const uploadedFileIds = uploadedFiles.map((x) => String(x.fileId));
  const attachmentFileCount =
    selectedAttachmentIds.length + uploadedFileIds.length;
  const displayedLetters = letters.filter(({ display }: ILetter) => display);
  const selectedLetters = displayedLetters.filter(
    ({ selected }: ILetter) => selected
  );
  const letterInputs = selectedLetters.map((x) => ({
    id: x.id,
    type: x.type,
    attachmentPathTemp: x.attachment?.path,
  }));
  const selectedLetterItems = !!selectedLetters.length;
  const multiple = selectedLetters.length > 1;
  const [showAttachFilesDialog, setShowAttachFilesDialog] = useState(false);
  const [sendToPrintShop, printShopState] = useSendToPrintShopMutation();
  const [print, printState] = useOnLetterPrintMutation();
  const [mergeAttachments, { isLoading: isMergeAttachments }] =
    useMergeAttachmentsMutation();

  useMutationEffect(
    printShopSuccess,
    printShopError,
    setMessage,
    printShopState,
    setLoading,
    () => {
      const updated = letters.map((letter: ILetter) => ({
        ...letter,
        printShop: letter.selected || letter.printShop,
      }));
      setLetters(updated);
    }
  );

  useMutationEffect(
    printSuccess,
    printError,
    setMessage,
    printState,
    setLoading,
    () => {
      const updated = letters.map((letter: ILetter) => ({
        ...letter,
        print: letter.selected || letter.print,
      }));
      setLetters(updated);
    }
  );

  const onPrintShopClick = () => {
    setLoading(true);
    setActionExecuted(true);
    sendToPrintShop({
      episodeId: Number(episodeId),
      patientId: Number(patientId),
      checklistId: 0,
      letters: letterInputs,
    });
  };

  const onPrintClick = () => {
    setLoading(true);
    setActionExecuted(true);
    print({
      episodeId: Number(episodeId),
      patientId: Number(patientId),
      checklistId: 0,
      letters: letterInputs,
    }).then(() => {
      printIframe();
    });
  };

  const onFaxClick = () => {
    setView('fax');
    setActionExecuted(true);
  };

  const handleCloseAttachFilesDialog = useCallback(() => {
    setShowAttachFilesDialog(false);
  }, []);

  const onPreviewClick = useCallback(() => {
    fetchPreview();
    setIsSelectedView(true);
    setActionExecuted(false);
  }, [fetchPreview, setIsSelectedView, setActionExecuted]);

  const attachFilesSuccess = useCallback(
    (filepath: string, attachmentFileCount: number) => {
      const filesToReplace = letters.filter(
        ({ attachment, selected }) => selected && attachment
      ).length;

      const filesToAdd = letters.filter(
        ({ attachment, selected }) => selected && !attachment
      ).length;

      const message = getAttachmentSuccessMessage(
        attachmentFileCount,
        filesToReplace,
        filesToAdd
      );

      const withFiles = letters.map((letter: ILetter) => ({
        ...letter,
        attachment: letter.selected
          ? { id: 0, path: filepath }
          : letter.attachment,
        attachmentFileCount: attachmentFileCount,
      }));
      setLetters(withFiles);
      setMessage(message);
      setPreview(null);
    },
    [letters, setLetters, setMessage, setPreview]
  );

  const onAttachFilesClick = useCallback(() => {
    setShowAttachFilesDialog(true);
  }, []);

  const onAttachError = useCallback(() => {
    const withErrors = letters.map((letter: ILetter) => ({
      ...letter,
      attachmentErrorCount: letter.selected
        ? letter.attachmentErrorCount + 1
        : letter.attachmentErrorCount,
    }));
    setMessage(attachmentError);
    setLetters(withErrors);
  }, [letters, setLetters, setMessage]);

  const mergeAttachmentCallback = useCallback(
    (attachmentFileCount: number, isError: boolean, filePath: string) => {
      setShowAttachFilesDialog(false);
      setIsSelectedView(false);
      if (!isError && filePath) {
        attachFilesSuccess(filePath, attachmentFileCount);
      } else {
        onAttachError();
      }
    },
    [attachFilesSuccess, onAttachError, setIsSelectedView]
  );

  const handleAttachFilesDialog = useCallback(() => {
    if (selectedAttachmentIds.length || uploadedFileIds.length) {
      mergeAttachments({
        attachmentIds: selectedAttachmentIds,
        fileIds: uploadedFileIds,
      }).then((result) => {
        if ('data' in result) {
          const filePath = result.data?.mergeAttachments?.filePath ?? '';
          const isError = result.data?.mergeAttachments?.isError ?? false;
          mergeAttachmentCallback(attachmentFileCount, isError, filePath);
        }
      });
    }
  }, [
    attachmentFileCount,
    mergeAttachments,
    mergeAttachmentCallback,
    selectedAttachmentIds,
    uploadedFileIds,
  ]);

  const faxAllowed = hasUserPrivilege(Privilege.AllowFax);
  const isPrintButtonActive = isSelectedView && selectedLetterItems;
  const isFaxButtonActive =
    selectedLetterItems &&
    !multiple &&
    faxParameter?.isLetterTransmittalByFax &&
    faxParameter?.isFaxConfiguredByRole &&
    faxParameter?.isFaxConfiguredByCareSite;
  const isPrintShopButtonActive = isPrintButtonActive && isPrintShopActive;
  return (
    <StyledButtonsWrapper>
      <ActionButton
        icon={ICONS.Preview}
        text="Preview"
        active={selectedLetterItems && !isSelectedView}
        isFromLetters={true}
        onClick={onPreviewClick}
      />
      <StyledDivider orientation="vertical" />
      <ActionButton
        icon={ICONS.Attachment}
        text="Attach"
        active={selectedLetterItems}
        isFromLetters={true}
        onClick={onAttachFilesClick}
      />
      <ActionButton
        icon={ICONS.Print}
        text="Desk Print"
        isFromLetters={true}
        active={isPrintButtonActive}
        onClick={onPrintClick}
      />
      <ActionButton
        icon={ICONS.Print_Shop}
        text="Print Shop"
        active={isPrintShopButtonActive}
        isFromLetters={true}
        onClick={onPrintShopClick}
      />
      {faxAllowed ? (
        <ActionButton
          icon={ICONS.Fax}
          text="Fax"
          active={isFaxButtonActive as boolean}
          isFromLetters={true}
          onClick={onFaxClick}
        />
      ) : null}
      {showAttachFilesDialog ? (
        <AttachFilesDialog
          open={showAttachFilesDialog}
          patientId={patientId}
          onClose={handleCloseAttachFilesDialog}
          onAttachFiles={handleAttachFilesDialog}
        />
      ) : null}
      <Loader active={isMergeAttachments} />
    </StyledButtonsWrapper>
  );
};
