import { UpdateMaskCode } from 'components/constants';
import { TextAttributeType } from 'graphql/graphqlTypes';

export const applyUpdateMaskCriteria = (
  value: string,
  textAttribute: TextAttributeType | null | undefined
): string => {
  const updateMask = textAttribute?.updateMask || '';
  const criteria = updateMask.split(',').map((item) => item.trim());

  let formatted = value;

  criteria.forEach((criterion) => {
    switch (criterion) {
      case UpdateMaskCode.allCaps:
        formatted = formatted.toUpperCase();
        break;
      case UpdateMaskCode.properCase:
        formatted = formatted
          .split(/(\s+)/)
          .map((word) =>
            word.trim()
              ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
              : word
          )
          .join('');
        break;
      case UpdateMaskCode.removeTrailingSpaces:
        formatted = formatted.trim();
        break;
      default:
        break;
    }
  });

  return formatted;
};
