import React from 'react';
import {
  AssociatedEpisodeActionValue,
  MedicationCode,
  MedicationCodeType,
  Provider,
} from 'graphql/graphqlTypes';
import { Box, Grid, Switch, TextField, styled } from '@mui/material';
import { COLORS } from 'consts/styles';
import { formatDate } from '../HealthService/Components/ServiceRow.helpers';
import {
  AssociatedEpisodeAttributeNames,
  firstCharCapitalize,
  getEpisodeValue,
  getStringCodeToMedicationCode,
  IAssociatedEpisodeAttributes,
} from './AssociatedEpisode.helper';
import StyledLink from 'components/actionlink/StyledLink';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';
import { useSelector } from 'react-redux';
import { IState } from 'store';
import DatePicker from '../HealthService/Components/DatePicker';
import { IUser } from 'backend/types/user';
import { ISelectItem, Select } from 'components/select';
import { isUndefined } from 'lodash';
import ProviderSearch from 'components/autocomplete/providerSearch';
import { validateRequired } from 'util/validationUtils';
import MedicationCodeSearch from 'components/autocomplete/medicationCodeSearch';

export const StyledGrid = styled(Grid)({
  marginBottom: '20px',
  marginTop: '5px',
  paddingBottom: '20px',
  backgroundColor: COLORS.GREY10,
  alignItems: 'center',
  marginLeft: '0',
  width: '100%',
});

export interface IAssociatedEpisodeTableProps {
  episodeAttributes: IAssociatedEpisodeAttributes[];
  actionValues: AssociatedEpisodeActionValue[];
  patientId: number;
  isReview: boolean;
  onDelete?: (episode: AssociatedEpisodeActionValue) => void;
  handleChange: (attribute: string, id: number, value: unknown) => void;
}

const AssociatedEpisodeTable = (props: IAssociatedEpisodeTableProps) => {
  const {
    episodeAttributes,
    actionValues,
    onDelete,
    handleChange,
    patientId,
    isReview,
  } = props;

  const accountUser = useSelector(
    (state: IState) => state.home.mainModel.users
  ) as IUser[];

  const lookups = useSelector((state: IState) => state.home.mainModel.lookups);

  const pathWayLookup = lookups
    .find((x) => x.name == AssociatedEpisodeAttributeNames.pathway)
    ?.values?.map((v) => {
      return { id: v.id.toString(), name: v.name };
    });

  const authLookup = lookups
    .find((x) => x.name == AssociatedEpisodeAttributeNames.authLookup)
    ?.values?.map((v) => {
      return { id: v.id.toString(), name: v.name };
    });

  const userLookup = accountUser.map((v) => {
    return { id: v.id.toString(), name: v.name };
  });

  const codeHandleChange = (
    value: MedicationCode[],
    attributeName: string,
    id: number
  ) => {
    const codeValue = value?.map((x) => x?.code?.trim())?.join(', ');
    handleChange(attributeName, id, codeValue);
  };

  const render = (
    attributeName: string,
    id: number,
    actionValue: AssociatedEpisodeActionValue
  ) => {
    switch (attributeName) {
      case AssociatedEpisodeAttributeNames.receivedDateTime:
        return (
          <DatePicker
            value={formatDate(actionValue.receivedDateTime ?? null)}
            onChange={(date) => handleChange(attributeName, id, date)}
            error={false}
          />
        );
      case AssociatedEpisodeAttributeNames.episodeDateTime:
        return (
          <DatePicker
            value={formatDate(actionValue.episodeDateTime ?? null)}
            onChange={(date) => handleChange(attributeName, id, date)}
            error={false}
          />
        );
      case AssociatedEpisodeAttributeNames.determinationDateTime:
        return (
          <DatePicker
            value={formatDate(
              (actionValue.determinationDateTime as Date) ?? null
            )}
            onChange={(date) => handleChange(attributeName, id, date)}
            error={false}
          />
        );
      case AssociatedEpisodeAttributeNames.nPI:
        return <>{actionValue.provider?.nPI ?? ''}</>;
      case AssociatedEpisodeAttributeNames.provider:
        return (
          <ProviderSearch
            patientId={patientId}
            value={actionValue?.provider as Provider}
            onChange={(value) => handleChange(attributeName, id, value)}
          />
        );
      case AssociatedEpisodeAttributeNames.pathway:
        return (
          <Select
            value={actionValue?.episodePathway?.toString()}
            onChange={(event) => {
              handleChange(attributeName, id, event.target.value);
            }}
            items={
              !isUndefined(pathWayLookup)
                ? (pathWayLookup as ISelectItem[])
                : []
            }
            size="small"
            fullWidth={false}
            id="pathway-select"
          />
        );
      case AssociatedEpisodeAttributeNames.authorizationStatus:
        return (
          <Select
            value={actionValue?.authorizationStatus?.toString()}
            onChange={(event) => {
              handleChange(attributeName, id, event.target.value);
            }}
            items={
              !isUndefined(authLookup) ? (authLookup as ISelectItem[]) : []
            }
            size="small"
            fullWidth={false}
            id="auth-select"
          />
        );
      case AssociatedEpisodeAttributeNames.owner:
        return (
          <Select
            value={actionValue?.ownerId?.toString()}
            onChange={(event) => {
              handleChange(attributeName, id, event.target.value);
            }}
            items={
              !isUndefined(userLookup) ? (userLookup as ISelectItem[]) : []
            }
            size="small"
            fullWidth={false}
            id="owner-select"
          />
        );
      case AssociatedEpisodeAttributeNames.decisionBy:
        return (
          <Select
            value={actionValue?.decisionById?.toString()}
            onChange={(event) => {
              handleChange(attributeName, id, event.target.value);
            }}
            items={
              !isUndefined(userLookup) ? (userLookup as ISelectItem[]) : []
            }
            size="small"
            fullWidth={false}
            id="decisionBy-select"
          />
        );
      case AssociatedEpisodeAttributeNames.episodeNumber: {
        const hasError =
          validateRequired(actionValue?.episodeId, true).hasError ?? false;
        return (
          <TextField
            type="text"
            size="medium"
            variant="outlined"
            name="episodeNumber"
            value={actionValue?.episodeId}
            onChange={(event) =>
              handleChange(attributeName, id, event.target.value)
            }
            required
            error={hasError}
          />
        );
      }
      case AssociatedEpisodeAttributeNames.parStatus: {
        const parValue = Boolean(actionValue?.requestingClinicianPar);
        return (
          <Switch
            checked={parValue}
            onChange={(event) => {
              handleChange(attributeName, id, event.target.checked);
            }}
          />
        );
      }
      case AssociatedEpisodeAttributeNames.diagnosisCode:
        return (
          <MedicationCodeSearch
            type={MedicationCodeType.Icd_10Cm}
            value={getStringCodeToMedicationCode(
              actionValue?.diagnosisCode as string
            )}
            onChange={(value) => {
              codeHandleChange(value as MedicationCode[], attributeName, id);
            }}
            isMultiple={true}
          />
        );
      case AssociatedEpisodeAttributeNames.serviceCode:
        return (
          <MedicationCodeSearch
            type={MedicationCodeType.CptHcpcs}
            value={getStringCodeToMedicationCode(
              actionValue?.procedureCode as string
            )}
            onChange={(value) => {
              codeHandleChange(value as MedicationCode[], attributeName, id);
            }}
            isMultiple={true}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      {actionValues.map((episode) => {
        return (
          <StyledGrid key={episode.id} container spacing={2} rowSpacing={3}>
            {episodeAttributes.map((attribute, index) => {
              return (
                <React.Fragment key={attribute.name}>
                  <Grid item xs={12} sm={3}>
                    {firstCharCapitalize(attribute.name)}
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    {episode.id < 0 && !isReview
                      ? render(attribute.value, episode.id, episode)
                      : getEpisodeValue(
                          episode,
                          attribute.value ?? '',
                          userLookup as ISelectItem[],
                          authLookup as ISelectItem[],
                          pathWayLookup as ISelectItem[]
                        )}
                  </Grid>
                  <Grid item xs={12} sm={1}>
                    {onDelete && index === 0 && (
                      <Box display="flex" justifyContent="flex-end" pr="16px">
                        <CustomTooltip title="Delete">
                          <StyledLink onClick={() => onDelete(episode)}>
                            Delete
                          </StyledLink>
                        </CustomTooltip>
                      </Box>
                    )}
                  </Grid>
                </React.Fragment>
              );
            })}
          </StyledGrid>
        );
      })}
    </>
  );
};
export default AssociatedEpisodeTable;
