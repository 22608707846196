import React, { useEffect, useState } from 'react';
import apiClient from 'util/axiosHttp';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showErrorPopup } from 'store/errorPopup/errorPopupSlice';
import Loader from 'components/loader';

export interface IInterQualProps {
  checklistId?: number;
  actionGuid?: string;
  fieldId?: number;
  buttonUid?: string;
  medicationCodesStr?: string;
  reviewId?: string;
  metComponentId?: string;
  criteriaMet?: string;
}

export const InterQual = () => {
  const interQualId = 'interQual';
  const [url, setUrl] = React.useState();
  const [message, setMessage] = React.useState<string>();
  const [iframeLoaded, setIframeLoaded] = useState<boolean>();
  const [iframeInited, setIframeInited] = useState<boolean>();
  const [parameters, setParameters] = useState<IInterQualProps>();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const script = document.createElement('script');
  script.src = '/lib/interqual-api-2.2.js';
  document.body.appendChild(script);

  const dispatch = useDispatch();

  const showErrorMessage = (errorMessage: string) => {
    dispatch(showErrorPopup({ message: errorMessage }));
  };

  const grabParameters = () => {
    setParameters({
      actionGuid: params.get('actionGuid')?.toString() as string,
      buttonUid: params.get('buttonUid')?.toString() as string,
      fieldId: +(params.get('fieldId') ?? 0),
      medicationCodesStr: params
        .get('medicationCodesStr')
        ?.toString() as string,
      metComponentId: params.get('metComponentId')?.toString() as string,
      reviewId: params.get('reviewId')?.toString() as string,
      checklistId: +(params.get('checklistId') ?? 0),
    });
  };

  const getUrl = async () => {
    try {
      await apiClient
        .post('InterQual/GetUrl', parameters, {
          responseType: 'json',
        })
        .then((response) => {
          setUrl(response.data.url);
        });
    } catch (err) {
      const messageToSend = 'Something went wrong. Could not load InterQual';
      setMessage(messageToSend);
      showErrorMessage(messageToSend);
      throw err;
    }
  };

  const interQualInit = () => {
    InterQualOnline.initialize({
      interqualFrameElementId: interQualId,
      guidelineRestricted: false,
      appHandlers: {
        errorHandler: errorHandlerInterQual,
        applicationReadyHandler: applicationReadyHandlerInterQual,
      },
      reviewHandlers: {
        reviewSavedHandler: reviewSavedHandlerInterQual,
      },
    });
  };

  const errorHandlerInterQual = (errorMessage: string) => {
    showErrorMessage(errorMessage);
  };

  const handlePostSavePdfResponse = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    response: any,
    closeWindow: boolean,
    clearButtonUid: string
  ) => {
    if (response.data && response.data.status === 'ok') {
      window.opener['$IqCallbackSavePdfComplete_' + clearButtonUid]();
      if (closeWindow) {
        window.close();
      }
    } else {
      if (response.data && response.data.status) {
        showErrorMessage(response.data.status);
      } else {
        showErrorMessage('Unknown error on save review summary PDF');
      }
    }
  };

  const applicationReadyHandlerInterQual = () => {
    if (parameters?.reviewId) {
      InterQualOnline.openReview({
        savedReviewId: parameters.reviewId,
      });
    } else {
      InterQualOnline.newReview({
        medicalCode: parameters?.medicationCodesStr,
      });
    }
  };

  const saveReviewSummaryPdf = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    result: any,
    closeWindow: boolean,
    reviewId: string,
    clearButtonUid: string
  ) => {
    try {
      const blob = new Blob([result], { type: 'application/pdf' });

      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        let base64data = reader.result as string;

        const removeFirstSubString = 'data:application/pdf;base64,';
        if (base64data.indexOf(removeFirstSubString) === 0) {
          base64data = base64data.substring(removeFirstSubString.length);
        }
        const payload = JSON.stringify({
          checklistId: parameters?.checklistId,
          actionGuid: parameters?.actionGuid,
          fieldId: parameters?.fieldId,
          reviewId: reviewId,
          pdfDocument: base64data,
          isCompleted: closeWindow,
        });
        try {
          window.opener['$IqCallbackSavePdfStart_' + clearButtonUid](payload);
        } catch {
          showErrorMessage(
            'Not critical error: Fail to monitor Start PDF Save event.'
          );
        }
        apiClient
          .post('InterQual/ReviewSummaryPdf', payload, {
            responseType: 'json',
          })
          .then((response) => {
            handlePostSavePdfResponse(response, closeWindow, clearButtonUid);
          })
          .catch((err) => {
            showErrorMessage('Error on save review summary PDF');
            throw err;
          });
      };
    } catch (err) {
      showErrorMessage('Error on save review summary PDF');
      throw err;
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const reviewSavedHandlerInterQual = (result: any) => {
    try {
      const isCompleted =
        result.review_xml.indexOf('CurrentStatusDesc="Completed"') > 0;

      let reviewId = result.UUID as string;
      if (!reviewId && parameters?.reviewId) {
        reviewId = parameters.reviewId;
      }
      setParameters({ ...parameters, reviewId: reviewId });

      const status = isCompleted ? 'completed' : 'in process';
      const clearButtonUid = (parameters?.buttonUid as string).replace(
        /-/g,
        ''
      );
      try {
        window.opener['$IqCallbackSetStatusAndReviewId_' + clearButtonUid](
          status,
          reviewId
        );
      } catch {
        showErrorMessage(
          'Not critical error: InterQual button label in the checklist not updated. Changes will be applied after checklist refresh'
        );
      }

      apiClient
        .post(
          'InterQual/ReviewSaved',
          JSON.stringify({
            checklistId: parameters?.checklistId,
            actionGuid: parameters?.actionGuid,
            fieldId: parameters?.fieldId,
            metComponentId: parameters?.metComponentId,
            reviewId: reviewId,
            xmlDocument: result.review_xml,
            isCompleted: isCompleted,
          }),
          {
            responseType: 'json',
          }
        )
        .then((response) => {
          setParameters({
            ...parameters,
            criteriaMet: response.data.criteriaMet as string,
          });

          try {
            window.opener['$IqCallbackSetMet_' + clearButtonUid](
              response.data.criteriaMet as string
            );
          } catch {
            showErrorMessage(
              'Not critical error: The linked with InterQual component was not updated. Changes will be applied after checklist refresh'
            );
          }
          InterQualOnline.reviewSummaryPdf(
            {
              savedReviewId: reviewId,
              includeNotes: 'true',
            },
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (resultPdf: any) =>
              saveReviewSummaryPdf(
                resultPdf,
                isCompleted,
                reviewId,
                clearButtonUid
              ),
            (errorMessage) => {
              showErrorMessage(
                `Error on save InterQual review. ${errorMessage}`
              );
            }
          );
        });
    } catch (err) {
      showErrorMessage(
        'Error on update checklist according to InterQual results'
      );
      throw err;
    }
  };
  useEffect(() => {
    if (!parameters && location) {
      grabParameters();
    }
    if (!url && parameters) {
      getUrl();
    }
    // Loading data in iframe (!iframeLoaded && url)...
    if (iframeLoaded && !iframeInited) {
      setIframeInited(true);
      interQualInit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iframeLoaded, iframeInited, parameters, location]);

  if (!url) {
    return <Loader active />;
  }

  return (
    <>
      {message ? (
        <div>{message}</div>
      ) : (
        <iframe
          id={interQualId}
          src={url}
          allowFullScreen
          onLoad={() => setIframeLoaded(true)}
          width="100%"
          height="1000px"
        />
      )}
    </>
  );
};

export default InterQual;
