import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type AddAttachmentsMutationVariables = Types.Exact<{
  attachments: Types.UpdateAttachmentDtoInput;
}>;

export type AddAttachmentsMutation = {
  __typename?: 'Mutation';
  addAttachments: Array<{
    __typename?: 'Attachment';
    id: number;
    name?: string | null;
    attachmentCategory?: {
      __typename?: 'LookupValue';
      id: number;
      name?: string | null;
    } | null;
  }>;
};

export const AddAttachmentsDocument = `
    mutation addAttachments($attachments: UpdateAttachmentDtoInput!) {
  addAttachments(model: $attachments) {
    id
    name
    attachmentCategory {
      id
      name
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    addAttachments: build.mutation<
      AddAttachmentsMutation,
      AddAttachmentsMutationVariables
    >({
      query: (variables) => ({ document: AddAttachmentsDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useAddAttachmentsMutation } = injectedRtkApi;
