export enum ROUTE {
  Preferences = '/User/Preferences',
  InterQual = '/Checklist/InterQual',
  Mcg = '/Mcg/OpenMcg',
  FaxPreview = '/FaxPreview/:id',
  Home = '/Patient/Home',
  Error = '/Error',
  RerunValidation = '#RerunValidation',
  SupportInformation = '/User/SupportInformation',
  Workflow = '/Workflow/:id',
  Checklist = '/Checklist/Actions/:id',
}
