import { ROUTE } from 'consts/ROUTE';
import { EpisodeAutoAssign } from 'graphql/graphqlTypes';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { openFaxFlowModal } from 'store/ui/modals/searchPatient';

export const useNavigateToAutoAssignState = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return useCallback(
    (episode: EpisodeAutoAssign) => {
      if (!episode) {
        return;
      }
      if (isUnAssignedFax(episode)) {
        if (Number(episode.attachmentId) > 0) {
          dispatch(
            openFaxFlowModal({
              episodeId: Number(episode.episodeId),
              episodeAttachmentId: Number(episode.attachmentId),
            })
          );
        }
      } else {
        navigate(getRoute(episode));
      }
    },
    [dispatch, navigate]
  );
};

const getRoute = (episode: EpisodeAutoAssign) => {
  if (episode.workflowInstanceId != null) {
    return `${ROUTE.Workflow.replace(':id', episode.workflowInstanceId)}`;
  } else if (episode.checklistId != null) {
    return `${ROUTE.Checklist.replace(':id', episode.checklistId.toString())}`;
  }
  return `${ROUTE.Home}`;
};

const isUnAssignedFax = (episode: EpisodeAutoAssign) => {
  return (episode.attachmentId ?? 0) > 0 && !episode.patientId;
};
