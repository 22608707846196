import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import {
  addressColumnExtension,
  addressColumns,
  attributeToType,
  EditAddress,
  LabelAddress,
} from './address';
import ContactsGrid from './contactsGrid';
import { CommunicationNeeds } from './communicationNeeds';
import { useCommitContactsChanges } from './contacts.hooks';
import Loader from 'components/loader';
import {
  EditEmail,
  emailColumnExtension,
  emailColumns,
  LabelEmail,
} from './emails';
import {
  EditPhone,
  LabelPhone,
  phoneColumnExtension,
  phoneColumns,
} from './phone';
import { useDispatch } from 'react-redux';
import { setIsInEditMode } from 'store/patientdetails/patientDetailsSlice';
import { useGetEntityAttributesByNameQuery } from 'graphql/hooks/getEntityAttributesByName';
import { TextAttributeType } from 'graphql/graphqlTypes';

interface IContactTabProps {
  disabled: boolean;
  isExternalDataReadOnly: boolean;
}

export type PatientAttributeType = {
  name: string;
  type: string;
  textAttribute: TextAttributeType | undefined | null;
};

const ContactTab = ({ disabled, isExternalDataReadOnly }: IContactTabProps) => {
  const dispatch = useDispatch();
  const {
    contactData,
    addressCommitChanges,
    emailCommitChanges,
    phoneCommitChanges,
    languageCommitChanges,
  } = useCommitContactsChanges();

  const [editEnabled, setEditEnabled] = useState(true);
  const [attributeTypes, setAttributeTypes] =
    useState<PatientAttributeType[]>();

  const {
    data: PatientEntityAttributes,
    isFetching: isFetchingAttributes,
    isSuccess: isSuccessFetchingAttributes,
  } = useGetEntityAttributesByNameQuery({
    entity: 'Patient',
    attributes: [
      'AlternateAddress1',
      'AlternateAddress2',
      'AlternateCity',
      'AlternateState',
      'AlternateZipCode',
      'ThirdAddress1',
      'ThirdAddress2',
      'ThirdCity',
      'ThirdState',
      'ThirdZipCode',
      'AlternatePhone',
      'AlternatePhone2',
      'EmailAddressAlternate',
    ],
  });

  useEffect(() => {
    if (
      !isFetchingAttributes &&
      isSuccessFetchingAttributes &&
      PatientEntityAttributes?.getEntityAttributesByName
    ) {
      const attributeTypes: PatientAttributeType[] = [];
      PatientEntityAttributes?.getEntityAttributesByName.forEach((x) => {
        if (x?.name) {
          attributeTypes.push({
            type: attributeToType[x.name as keyof typeof attributeToType],
            name: x.name,
            textAttribute: x.attributeType as TextAttributeType,
          });
        }
      });
      setAttributeTypes(attributeTypes);
    }
  }, [
    PatientEntityAttributes,
    isFetchingAttributes,
    isSuccessFetchingAttributes,
  ]);

  useEffect(() => {
    dispatch(setIsInEditMode({ isInEditMode: !editEnabled }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editEnabled]);

  if (!contactData) {
    return <Loader active={true} />;
  }

  return (
    <Grid container>
      <Grid item>
        <ContactsGrid
          title="Addresses"
          itemName="address"
          rows={contactData.addresses}
          columns={addressColumns}
          commitChanges={addressCommitChanges}
          editCell={EditAddress}
          labelCell={(p) =>
            LabelAddress({ ...p, disabled: disabled || !editEnabled })
          }
          getRowId={(row) => row.type}
          editEnabled={!disabled && editEnabled}
          setEditEnabled={setEditEnabled}
          columnExtensions={addressColumnExtension}
          isExternalDataReadOnly={isExternalDataReadOnly}
          isSystemUser={contactData.isSystemUser}
          attributeTypes={attributeTypes}
        />
      </Grid>
      <Grid item>
        <ContactsGrid
          title="Email"
          itemName="email"
          rows={contactData.emails}
          columns={emailColumns}
          commitChanges={emailCommitChanges}
          editCell={EditEmail}
          labelCell={(p) =>
            LabelEmail({ ...p, disabled: disabled || !editEnabled })
          }
          getRowId={(row) => row.type}
          editEnabled={!disabled && editEnabled}
          setEditEnabled={setEditEnabled}
          columnExtensions={emailColumnExtension}
          isExternalDataReadOnly={isExternalDataReadOnly}
          isSystemUser={contactData.isSystemUser}
          attributeTypes={attributeTypes}
        />
      </Grid>
      <Grid item md={7}>
        <ContactsGrid
          title="Phone Numbers"
          itemName="phone number"
          rows={contactData.phones}
          columns={phoneColumns}
          commitChanges={phoneCommitChanges}
          editCell={EditPhone}
          labelCell={(p) =>
            LabelPhone({ ...p, disabled: disabled || !editEnabled })
          }
          getRowId={(row) => row.type}
          editEnabled={!disabled && editEnabled}
          setEditEnabled={setEditEnabled}
          columnExtensions={phoneColumnExtension}
          isExternalDataReadOnly={isExternalDataReadOnly}
          isSystemUser={contactData.isSystemUser}
          attributeTypes={attributeTypes}
        />
      </Grid>
      <Grid item md={5}>
        <CommunicationNeeds
          disabled={disabled}
          language={contactData.language}
          languageBarriers={contactData.languageBarriers}
          languageCommitChanges={languageCommitChanges}
        />
      </Grid>
    </Grid>
  );
};

export default ContactTab;
