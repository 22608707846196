import React from 'react';
import { OutlinedInput, Theme } from '@mui/material';
import AutocompleteInput from './autocompleteInput';
import { Maybe } from 'graphql/graphqlTypes';
import { SxProps } from '@mui/system';
import { HtmlEditor } from '@PerformanceClincial/checklist-ui';

interface ChecklistTextInputProps {
  disabled: boolean;
  userDefinedId?: Maybe<string>;
  dataTestid: string;
  value: string;
  multiline: boolean;
  richText: boolean;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string
  ) => void;
  onBlur: () => void;
  onItemSelected: (value: string) => void;
  error: boolean;
  inputProps: { [k: string]: any };
  key: string;
  useAutocomplete?: boolean;
  sx?: SxProps<Theme>;
}

const ChecklistTextInput = (props: ChecklistTextInputProps) => {
  const { dataTestid, userDefinedId, useAutocomplete, richText } = props;

  if (useAutocomplete) {
    return <AutocompleteInput autoSize={true} {...props} />;
  }
  return richText ? (
    <HtmlEditor
      id={''}
      {...props}
      width="100%"
      height={200}
      menubar={false}
      toolbar={`bold underline italic | bullist numlist`}
      {...(userDefinedId && {
        'data-userdefinedid': userDefinedId,
      })}
      data-testid={dataTestid}
    />
  ) : (
    <OutlinedInput
      {...props}
      {...(userDefinedId && {
        'data-userdefinedid': userDefinedId,
      })}
      data-testid={dataTestid}
      type="text"
      fullWidth={true}
    />
  );
};

export default ChecklistTextInput;
