import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetEntityAttributesByNameQueryVariables = Types.Exact<{
  entity: Types.Scalars['String'];
  attributes: Array<Types.Scalars['String']> | Types.Scalars['String'];
}>;

export type GetEntityAttributesByNameQuery = {
  __typename?: 'EntitiesQuery';
  getEntityAttributesByName?: Array<{
    __typename?: 'EntityAttributeDef';
    entityName?: string | null;
    name?: string | null;
    attributeType?:
      | {
          __typename: 'BoolAttributeType';
          type: string;
          name?: string | null;
          displayName: string;
          entityName: string;
          fullName: string;
          dataType?: string | null;
        }
      | {
          __typename: 'DateAttributeType';
          type: string;
          name?: string | null;
          displayName: string;
          entityName: string;
          fullName: string;
          dataType?: string | null;
        }
      | {
          __typename: 'DateTimeAttributeType';
          type: string;
          name?: string | null;
          displayName: string;
          entityName: string;
          fullName: string;
          dataType?: string | null;
        }
      | { __typename?: 'ExpressionAttributeType' }
      | {
          __typename: 'FloatAttributeType';
          type: string;
          name?: string | null;
          displayName: string;
          entityName: string;
          fullName: string;
          dataType?: string | null;
        }
      | {
          __typename: 'IntAttributeType';
          type: string;
          name?: string | null;
          displayName: string;
          entityName: string;
          fullName: string;
          dataType?: string | null;
          maxLength?: number | null;
          regexMask?: string | null;
          regexErrorText?: string | null;
        }
      | {
          __typename: 'LookupAttributeType';
          type: string;
          name?: string | null;
          displayName: string;
          entityName: string;
          fullName: string;
          dataType?: string | null;
          multiValues: boolean;
          lookup?: {
            __typename?: 'Lookup';
            id: number;
            name?: string | null;
            isDeleted?: boolean | null;
          } | null;
        }
      | {
          __typename: 'ReferenceAttributeType';
          type: string;
          name?: string | null;
          displayName: string;
          entityName: string;
          fullName: string;
          dataType?: string | null;
          tableName?: string | null;
          isBigTable: boolean;
          required: boolean;
        }
      | {
          __typename: 'TextAttributeType';
          type: string;
          name?: string | null;
          displayName: string;
          entityName: string;
          fullName: string;
          dataType?: string | null;
          maxLength?: number | null;
          regexMask?: string | null;
          updateMask?: string | null;
          regexErrorText?: string | null;
          required: boolean;
        }
      | null;
  } | null> | null;
};

export const GetEntityAttributesByNameDocument = `
    query getEntityAttributesByName($entity: String!, $attributes: [String!]!) {
  getEntityAttributesByName(entity: $entity, attributes: $attributes) {
    entityName
    name
    attributeType {
      ... on TextAttributeType {
        __typename
        type
        name
        displayName
        entityName
        fullName
        dataType
        maxLength
        regexMask
        updateMask
        regexErrorText
        required
      }
      ... on IntAttributeType {
        __typename
        type
        name
        displayName
        entityName
        fullName
        dataType
        maxLength
        regexMask
        regexErrorText
      }
      ... on FloatAttributeType {
        __typename
        type
        name
        displayName
        entityName
        fullName
        dataType
      }
      ... on BoolAttributeType {
        __typename
        type
        name
        displayName
        entityName
        fullName
        dataType
      }
      ... on DateTimeAttributeType {
        __typename
        type
        name
        displayName
        entityName
        fullName
        dataType
      }
      ... on DateAttributeType {
        __typename
        type
        name
        displayName
        entityName
        fullName
        dataType
      }
      ... on LookupAttributeType {
        __typename
        type
        name
        displayName
        entityName
        fullName
        dataType
        lookup {
          id
          name
          isDeleted
        }
        multiValues
      }
      ... on ReferenceAttributeType {
        __typename
        type
        name
        displayName
        entityName
        fullName
        dataType
        tableName
        isBigTable
        required
      }
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getEntityAttributesByName: build.query<
      GetEntityAttributesByNameQuery,
      GetEntityAttributesByNameQueryVariables
    >({
      query: (variables) => ({
        document: GetEntityAttributesByNameDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetEntityAttributesByNameQuery,
  useLazyGetEntityAttributesByNameQuery,
} = injectedRtkApi;
