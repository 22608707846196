import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetAutoAssignEpisodeQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetAutoAssignEpisodeQuery = {
  __typename?: 'EntitiesQuery';
  getAutoAssignEpisode?: {
    __typename?: 'EpisodeAutoAssign';
    episodeId?: number | null;
    episodeNumber?: string | null;
    careSiteId?: number | null;
    careSiteName?: string | null;
    patientId?: number | null;
    careStageName?: string | null;
    workflowInstanceId?: string | null;
    checklistId?: number | null;
    attachmentId?: number | null;
    status?: Types.AutoAssignStatus | null;
  } | null;
};

export const GetAutoAssignEpisodeDocument = `
    query getAutoAssignEpisode {
  getAutoAssignEpisode {
    episodeId
    episodeNumber
    careSiteId
    careSiteName
    patientId
    careStageName
    workflowInstanceId
    checklistId
    attachmentId
    status
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAutoAssignEpisode: build.query<
      GetAutoAssignEpisodeQuery,
      GetAutoAssignEpisodeQueryVariables | void
    >({
      query: (variables) => ({
        document: GetAutoAssignEpisodeDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetAutoAssignEpisodeQuery,
  useLazyGetAutoAssignEpisodeQuery,
} = injectedRtkApi;
