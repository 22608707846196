import React from 'react';
import { PatientClaimDto } from 'graphql/graphqlTypes';
import { useGetEpisodeClaimsQuery } from 'graphql/hooks/getEpisodeClaims';
import ClaimsGrid from './ClaimsGrid';

const EpisodeClaims = (props: { episodeId: number }) => {
  const { episodeId } = props;
  const { data, isFetching } = useGetEpisodeClaimsQuery({ episodeId });
  const claimData = (data?.getEpisodeClaims as PatientClaimDto[]) ?? [];

  return <ClaimsGrid claims={claimData} isFetching={isFetching} />;
};

export default EpisodeClaims;
