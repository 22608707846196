import React from 'react';
import { Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { COLORS } from 'consts/styles';
import styled from 'styled-components';
import DialogTitle from 'components/modal/DialogTitle';
import DialogContent from 'components/modal/DialogContent';
import DialogActions from 'components/modal/DialogActions';
import { AttachFilesDialogActions } from './attachFilesDialogActions';
import AttachFilesDialogBody from './attachFilesDialogBody';

const StyledTypography = styled(Typography)({
  color: COLORS.WHITE,
});

const StyledDialogContent = styled(DialogContent)({
  backgroundColor: COLORS.PALE_GREY,
  paddingLeft: '24px',
  paddingRight: '24px',
});

const StyledDialogActions = styled(DialogActions)({
  padding: '16px 24px',
  justifyContent: 'space-between',
});

export interface AttachFilesDialogProps {
  open: boolean;
  patientId: number;
  onClose(): void;
  onAttachFiles(): void;
}

const AttachFilesDialog = (props: AttachFilesDialogProps) => {
  const { open, patientId, onClose, onAttachFiles } = props;

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth="xl"
      fullWidth
    >
      <DialogTitle id="customized-dialog-title" onClose={onClose}>
        <StyledTypography variant="subtitle1">Attach Files</StyledTypography>
      </DialogTitle>
      <StyledDialogContent dividers>
        <AttachFilesDialogBody patientId={patientId} />
      </StyledDialogContent>
      <StyledDialogActions>
        <AttachFilesDialogActions
          handleClose={onClose}
          handleAttachFiles={onAttachFiles}
        />
      </StyledDialogActions>
    </Dialog>
  );
};

export default AttachFilesDialog;
