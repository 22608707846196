import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import { Box, IconButton, Typography } from '@mui/material';
import { Grid } from '@devexpress/dx-react-grid-material-ui';
import { usePrevious } from 'hooks';
import useDownloadFile from 'hooks/useDownloadFile';
import styled from 'styled-components';
import { IState } from 'store';
import { clearAttachments } from 'store/patientdetails/patientDetails.attachment.slice';
import { resetUploadedFiles } from 'store/fileUpload/fileUploadSlice';
import { ChecklistStorageType } from 'store/actions/types';
import { IUploadedFile } from 'store/fileUpload/types';
import { useAddAttachmentsMutation } from 'graphql/hooks/addAttachments';
import {
  AttachmentDtoInput,
  AttachmentGroupActionValue,
  ChecklistItemAttachmentGroup,
  UpdateAttachmentDtoInput,
} from 'graphql/graphqlTypes';
import { COLORS } from 'consts/styles';
import {
  SymphonyTable,
  SymphonyTableHeaderRow,
} from 'components/gridFormatters';
import Loader from 'components/loader';
import { INDIVIDUAL_COMPONENT_CODE } from 'components/constants';
import useComponentHasFailedRequest from 'components/failedRequests/FailedRequests.helper';
import DateTimeProvider from 'components/home/grid/providers/DateTimeProvider';
import Icon, { ICONS } from 'components/icon';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';
import { ActionButton } from 'features/letters/list/ActionButton';
import AttachFilesDialog from 'features/letters/attachFilesDialog';
import { AttachmentGroupActionsProvider } from './columnFormatters/AttachmentGroupActionsProvider';
import { AttachmentGroupColumns } from './AttachmentGroup.helper';
import { useUpdateActionValue } from '../UpdateActionValue.helpers';
import {
  StyledSummaryBox,
  StyledSummaryGridBox,
} from '../LetterSummary/LetterSummary';

export interface IChecklistItemAttachmentGroupProps {
  storageType: ChecklistStorageType;
  item: ChecklistItemAttachmentGroup;
  isReadOnly: boolean;
}

const StyledBox = styled(Box)({
  display: 'flex',
  paddingLeft: '36px',
  paddingBottom: '24px',
  backgroundColor: COLORS.WHITE,
});
const StyledIconButton = styled(IconButton)({
  marginTop: '4px',
  width: '40px',
});

const AttachmentGroup = ({
  storageType,
  item,
  isReadOnly,
}: IChecklistItemAttachmentGroupProps) => {
  const dispatch = useDispatch();
  const [showAttachFilesDialog, setShowAttachFilesDialog] = useState(false);
  const [attachmentGroupActionValue, setAttachmentGroupActionValue] = useState<
    AttachmentGroupActionValue[]
  >((item.agValue ?? []) as AttachmentGroupActionValue[]);
  const previousAttachmentGroupActionValue = usePrevious(
    attachmentGroupActionValue
  );
  const currentUser = useSelector((state: IState) => state.user.currentUser);
  const patientId = useSelector(
    (state: IState) =>
      Number(state.checklist.documentsState[storageType].patientId) ?? 0
  );
  const checklist = useSelector(
    (state: IState) => state.checklist.documentsState[storageType].checklist
  );
  const checklistId = Number(checklist?.id) ?? 0;
  const episodeId = Number(checklist?.episodeId);
  const attachments = useSelector(
    (state: IState) => state.patientDetailsAttachment ?? []
  );
  const uploadedFiles = useSelector(
    (state: IState) => state.fileUpload.uploadedFiles
  );
  const isOptimizedView = useSelector(
    (state: IState) => state.ui.print.documents.isOptimizedView
  );
  const canExport = attachmentGroupActionValue.length > 0 && !isReadOnly;
  const isUpdateActionValueRequestFailed = useComponentHasFailedRequest(
    item.uid
  );
  const { updateActionValueExtended } = useUpdateActionValue();
  const downloadFile = useDownloadFile();
  const [
    addAttachments,
    {
      isLoading: isAddingAttachments,
      isSuccess: isSuccessAddAttachments,
      data: attachmentResults,
    },
  ] = useAddAttachmentsMutation();

  const onDeleteCallback = (id: number) => {
    const attachments = attachmentGroupActionValue.filter(
      (x) => x.attachment.id !== id
    );
    setAttachmentGroupActionValue(attachments);
  };

  const gridColumns = [
    {
      name: AttachmentGroupColumns.createdOn,
      title: 'Date/Time',
    },
    {
      name: AttachmentGroupColumns.name,
      title: 'Attachment Name',
      getCellValue: (row: AttachmentGroupActionValue) => row?.attachment.name,
    },
    {
      name: AttachmentGroupColumns.category,
      title: 'Attachment Category',
      getCellValue: (row: AttachmentGroupActionValue) =>
        row?.attachment.attachmentCategory?.name,
    },
    {
      name: AttachmentGroupColumns.addedBy,
      title: 'Added By',
      getCellValue: (row: AttachmentGroupActionValue) =>
        row?.createdByUser?.fullName,
    },
    {
      name: AttachmentGroupColumns.actions,
      title: 'Actions',
      getCellValue: (row: AttachmentGroupActionValue) => ({
        attachment: row.attachment,
        isReadOnly,
        onDeleteCallback,
      }),
    },
  ];

  const resetState = useCallback(() => {
    dispatch(clearAttachments());
    dispatch(resetUploadedFiles());
    setShowAttachFilesDialog(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAttachFilesClick = useCallback(() => {
    setShowAttachFilesDialog(true);
  }, []);

  const handleCloseAttachFilesDialog = useCallback(() => {
    resetState();
  }, [resetState]);

  const mapAttachmentGroupActionValue = (attachment: unknown) => {
    return {
      attachment: attachment,
      createdByUser: {
        id: currentUser.id,
        fullName: currentUser.name,
      },
      createdOn: new Date().toISOString(),
    } as AttachmentGroupActionValue;
  };

  const handleAttachFilesDialog = useCallback(() => {
    resetState();
    const existingIds = new Set(
      attachmentGroupActionValue.map((x) => x.attachment.id)
    );
    const newIds = attachments.selectedAttachmentIds.filter(
      (x) => !existingIds.has(x)
    );
    const selectedAttachments =
      attachments.attachmentsTab
        ?.filter((x) => newIds.includes(x.id))
        .map(mapAttachmentGroupActionValue) ?? [];

    if (selectedAttachments.length > 0) {
      setAttachmentGroupActionValue((prevItems) => [
        ...prevItems,
        ...selectedAttachments,
      ]);
    }
    uploadFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachmentGroupActionValue, attachments, uploadedFiles, resetState]);

  const uploadFiles = () => {
    if (uploadedFiles.length === 0) {
      return;
    }
    const attachment = (uploadedFile: IUploadedFile): AttachmentDtoInput => {
      return {
        attachmentId: 0,
        attachmentIsDeleted: false,
        attachmentName: String(uploadedFile.name),
        attachmentPathTemp: uploadedFile.fileId,
        attachmentCategoryId: uploadedFile.categoryId,
      };
    };
    const episodeAttachments = (): UpdateAttachmentDtoInput => {
      const attachments = uploadedFiles.map(attachment);
      return {
        attachments,
        episodeId,
      };
    };
    addAttachments({ attachments: episodeAttachments() });
  };

  const handleExport = useCallback(() => {
    if (canExport) {
      downloadFile(
        'export/attachments',
        {
          ids: attachmentGroupActionValue.map((x) => x.attachment.id),
        },
        'blob'
      );
    }
  }, [canExport, downloadFile, attachmentGroupActionValue]);

  useEffect(() => {
    if (
      isReadOnly ||
      !attachmentGroupActionValue ||
      !previousAttachmentGroupActionValue ||
      isEqual(attachmentGroupActionValue, previousAttachmentGroupActionValue)
    ) {
      return;
    }
    const attachments = attachmentGroupActionValue.map((x) => {
      return {
        attachmentId: x.attachment.id,
        createdOn: x.createdOn,
        createdBy: x.createdByUser?.id,
      };
    });
    const data = {
      checklistId: checklistId,
      componentId: INDIVIDUAL_COMPONENT_CODE,
      id: item.uid,
      type: 'string',
      value: JSON.stringify(attachments),
    };
    updateActionValueExtended(data, item.uid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    attachmentGroupActionValue,
    previousAttachmentGroupActionValue,
    isReadOnly,
  ]);

  useEffect(() => {
    if (!isSuccessAddAttachments || !attachmentResults?.addAttachments) {
      return;
    }
    const attachments = attachmentResults.addAttachments.map(
      mapAttachmentGroupActionValue
    );
    setAttachmentGroupActionValue((prevItems) => [
      ...prevItems,
      ...attachments,
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachmentResults, isSuccessAddAttachments]);

  return (
    <Box id={`uuid-${item.uid}`}>
      <Loader active={isAddingAttachments} />
      <StyledSummaryBox isPrinting={isOptimizedView}>
        <Typography variant="body2">
          {item.options?.codeLabel ?? 'Attachment Group'}
        </Typography>
      </StyledSummaryBox>
      <StyledBox>
        <ActionButton
          icon={ICONS.Attachment}
          text="Attach"
          active={!isReadOnly}
          onClick={handleAttachFilesClick}
        />
        <CustomTooltip title="Export All Files">
          <StyledIconButton
            onClick={handleExport}
            data-testid="export-attachment-group"
            size="large"
            disabled={!canExport}
          >
            <Icon
              size={20}
              icon={ICONS.ExportNew}
              color={canExport ? COLORS.SYMPHONY_BLUE : COLORS.GREY60}
            />
          </StyledIconButton>
        </CustomTooltip>
      </StyledBox>
      <StyledSummaryGridBox isPrinting={isOptimizedView}>
        <Grid
          rows={attachmentGroupActionValue}
          columns={gridColumns}
          getRowId={(row: AttachmentGroupActionValue) => row.attachment.id}
        >
          <DateTimeProvider for={[AttachmentGroupColumns.createdOn]} />
          <AttachmentGroupActionsProvider
            for={[AttachmentGroupColumns.actions]}
          />
          <SymphonyTable />
          <SymphonyTableHeaderRow />
        </Grid>
      </StyledSummaryGridBox>
      {isUpdateActionValueRequestFailed && (
        <Typography style={{ paddingLeft: '36px', color: 'red' }}>
          Internal server error: Your changes could not be saved, because of
          technical issues.
        </Typography>
      )}
      {showAttachFilesDialog ? (
        <AttachFilesDialog
          open={showAttachFilesDialog}
          patientId={patientId}
          onClose={handleCloseAttachFilesDialog}
          onAttachFiles={handleAttachFilesDialog}
        />
      ) : null}
    </Box>
  );
};

export default AttachmentGroup;
