import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTimer } from 'react-timer-hook';
import { IState } from 'store';
import { ChecklistStorageType } from 'store/actions/types';
import {
  Box,
  Typography,
  Button,
  styled,
  debounce,
  SxProps,
} from '@mui/material';
import { INDIVIDUAL_COMPONENT_CODE } from 'components/constants';
import {
  AssociatedEpisodeActionValue,
  ChecklistItem,
  ChecklistItemAssociatedEpisodes,
  Episode,
  Maybe,
  Provider,
  UpdateActionValueModelInput,
} from 'graphql/graphqlTypes';
import {
  useLazySearchEpisodesQuery,
  useSearchEpisodesQuery,
} from 'graphql/hooks/searchEpisodes';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';
import EpisodeSearchDialog from 'components/EpisodeSearchDialog/EpisodeSearchDialog';
import { useUpdateActionValue } from '../UpdateActionValue.helpers';
import EpisodeSearch from './EpisodeSearch';
import AssociatedEpisodeTable from './AssociatedEpisodeTable';
import {
  AssociatedEpisodeAttributeNames,
  associatedEpisodeAttributeHelper,
  convertParStatusValue,
} from './AssociatedEpisode.helper';
import date from 'yup/lib/date';
import { setChecklistComponentValidation } from 'store/actions/checklistSlice';
import {
  validateMinAmountValues,
  validateRequired,
} from 'util/validationUtils';
import { COLORS } from 'consts/styles';

export const EpisodeBox = styled(Box)({
  display: 'flex',
  paddingTop: '12px',
  paddingBottom: '12px',
});

export interface IAssociatedEpisodeProps {
  item: ChecklistItemAssociatedEpisodes;
  autoSave: boolean;
  categoryId: string;
  orderableIndex: number;
  storageType: ChecklistStorageType;
  isReadOnly: boolean;
  updateChecklistItemInputValueOnSave: (
    item: ChecklistItem,
    index: number,
    data: UpdateActionValueModelInput
  ) => void;
}

const AssociatedEpisode = (props: IAssociatedEpisodeProps) => {
  const {
    item,
    autoSave,
    orderableIndex,
    storageType,
    isReadOnly,
    categoryId,
    updateChecklistItemInputValueOnSave,
  } = props;
  const { minRequired, maxAllowed } = item.options;
  const itemOrItems = maxAllowed > 1 ? 'items' : 'item';
  const validStyle = {
    border: 1,
    borderColor: 'transparent',
    borderRadius: 1,
  };
  const notValidStyle = { ...validStyle, borderColor: COLORS.RED100 };
  const [outerValidationStyle, setOuterValidationStyle] =
    useState<SxProps>(validStyle);
  const [buttonValidationStyle, setButtonValidationStyle] =
    useState<SxProps>(validStyle);
  const addEpisodeButtonTooltipText = `Maximum ${maxAllowed} ${itemOrItems} can be added`;
  const patientId = Number(
    useSelector(
      (state: IState) => state.checklist.documentsState[storageType].patientId
    ) ?? 0
  );
  const checklistId = useSelector(
    (state: IState) => state.checklist.documentsState[storageType].checklist?.id
  );
  const [selectedEpisodes, setSelectedEpisodes] = useState<Episode[]>([]);
  const [model, setModel] = useState<AssociatedEpisodeActionValue[]>(
    (item.actionValue ?? []) as AssociatedEpisodeActionValue[]
  );
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isDuplicateEpisodeId, setIsDuplicateEpisodeId] = useState(false);
  const [advancedSearchOpen, setAdvancedSearchOpen] = useState(false);
  const { updateActionValueExtended } = useUpdateActionValue();

  const dispatch = useDispatch();
  const { data: allEpisodeData, isFetching: isAllEpisodeLoading } =
    useSearchEpisodesQuery(
      { request: { patientId } },
      { skip: patientId === 0, refetchOnMountOrArgChange: true }
    );
  const [
    searchEpisodes,
    {
      data: searchEpisodeData,
      isFetching: isSearchEpisodeLoading,
      isSuccess: isSearchEpisodeSuccess,
    },
  ] = useLazySearchEpisodesQuery();
  const useDefaultEpisodes =
    searchTerm.length < 3 || isSearchEpisodeLoading || !isSearchEpisodeSuccess;
  const episodes = (
    useDefaultEpisodes
      ? allEpisodeData?.searchEpisodes.rows
      : searchEpisodeData?.searchEpisodes.rows
  ) as Episode[];

  const totalCount = (
    useDefaultEpisodes
      ? allEpisodeData?.searchEpisodes.totalCount
      : searchEpisodeData?.searchEpisodes.totalCount
  ) as number;

  const { restart } = useTimer({
    expiryTimestamp: new Date(),
    autoStart: false,
    onExpire: () => {
      searchEpisodes({
        request: {
          searchTerm,
          patientId,
        },
      });
    },
  });

  const handleValidation = (
    newEpisodes: Maybe<AssociatedEpisodeActionValue>[],
    willShowSearchBar?: boolean
  ) => {
    let error = false;
    error = validateMinimumNoOfEpisode(newEpisodes.length) || error;
    error = validateEpisodeId(newEpisodes) || error;
    error = isDuplicateEpisodeId || error;
    isDuplicateEpisodeId && validateDuplicateEpisodeId();
    setOuterValidationStyle(error ? getOuterVAlidationStyle() : validStyle);
    setButtonValidationStyle(error ? getButtonValidationStyle() : validStyle);

    function getButtonValidationStyle(): React.SetStateAction<SxProps> {
      return model.length == 0 && !willShowSearchBar
        ? notValidStyle
        : validStyle;
    }

    function getOuterVAlidationStyle(): React.SetStateAction<SxProps> {
      return model.length > 0 || willShowSearchBar ? notValidStyle : validStyle;
    }
  };

  const checkDuplicateEpisodeId = (newEpisodeId: Maybe<string>) => {
    const findIndex = model.findIndex((x) => x.episodeId == newEpisodeId);
    if (findIndex >= 0) {
      setIsDuplicateEpisodeId(true);
    } else {
      setIsDuplicateEpisodeId(false);
    }
  };

  const checkDuplicatesEpisodeId = (
    updatedModel: AssociatedEpisodeActionValue[]
  ) => {
    const episodeIds = updatedModel.map((x) => x.episodeId);
    setIsDuplicateEpisodeId(new Set(episodeIds).size !== episodeIds.length);
  };

  const validateDuplicateEpisodeId = () => {
    const message = 'Duplicate Episode Number';
    dispatch(
      setChecklistComponentValidation({
        storageType,
        error: {
          uuid: item.uid,
          error: message,
          fieldName: 'Associated Episodes : Episode Number',
          categoryId: categoryId,
          isValid: !message,
        },
      })
    );
  };
  const validateEpisodeId = (
    newEpisodes: Maybe<AssociatedEpisodeActionValue>[]
  ): boolean => {
    const errors = newEpisodes
      ?.map((x) => validateRequired(x?.episodeId, true))
      ?.filter((x) => x.hasError);

    errors?.forEach((x) => {
      dispatch(
        setChecklistComponentValidation({
          storageType,
          error: {
            uuid: item.uid,
            error: x.message ?? '',
            fieldName: 'Associated Episodes : Episode Number',
            categoryId: categoryId,
            isValid: !x.message,
          },
        })
      );
    });

    return errors == null ? false : errors.length > 0;
  };

  const validateMinimumNoOfEpisode = (noOfEpisodes: number): boolean => {
    const result = validateMinAmountValues(noOfEpisodes, minRequired);
    dispatch(
      setChecklistComponentValidation({
        storageType,
        error: {
          uuid: item.uid,
          error: result.message ?? '',
          fieldName: 'Associated Episodes',
          categoryId: categoryId,
          isValid: !result.message,
        },
      })
    );
    return result.hasError;
  };

  useEffect(() => {
    if (searchTerm.length < 3) {
      return;
    }
    const now = new Date();
    restart(new Date(now.getTime() + 1000));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, patientId]);

  useEffect(() => {
    handleValidation(model);
    handleInputChange(model);

    return () => {
      clearValidations();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);

  const clearValidations = () => {
    dispatch(
      setChecklistComponentValidation({
        storageType,
        error: {
          uuid: item.uid,
          error: '',
          fieldName: 'Associated Episodes',
          categoryId: categoryId,
          isValid: true,
        },
      })
    );
    setOuterValidationStyle(validStyle);
    setButtonValidationStyle(validStyle);
  };

  const handleDeleteEpisode = (actionValue: AssociatedEpisodeActionValue) => {
    const updatedModel = model.filter((x) => x.id !== actionValue.id);
    checkDuplicatesEpisodeId(updatedModel);
    setModel(updatedModel);
  };

  const handleEpisodeSelection = (episode: Episode) => {
    const newItem = {
      id: episode.id,
      episodeId: episode.episodeNumber,
      episodeDateTime: episode.createdOn,
      authorizationStatus: episode.authorizationStatus?.id,
      diagnosisCode: episode.diagnosisCodesAll?.map((x) => x?.code)?.join(', '),
      procedureCode: episode.procedureCodesAll?.map((x) => x?.code)?.join(', '),
      requestingProviderId: episode.requestingClinician?.id,
      nPI: episode.requestingClinician?.nPI,
      provider: episode.requestingClinician,
      decisionDateTime: episode.determinationDate,
      receivedDateTime: episode.faxDateTime,
      ownerId: episode.owner?.id,
      episodePathway: episode?.entityAttributes?.find(
        (x) => x?.name == AssociatedEpisodeAttributeNames.pathway
      )?.lookup?.id,
      determinationDateTime: episode?.determinationDate,
      requestingClinicianPar: convertParStatusValue(
        episode?.entityAttributes?.find(
          (x) =>
            x?.name == AssociatedEpisodeAttributeNames.requestingClinicianPar
        )?.value as string
      ),
      patientId: patientId,
    } as AssociatedEpisodeActionValue;
    const findIndex = model.findIndex((x) => x.episodeId == newItem.episodeId);
    if (findIndex < 0) {
      setSelectedEpisodes([...selectedEpisodes, episode]);
      setModel([...model, newItem]);
    }
    setSearchTerm('');
    setShowSearchBar(false);
  };

  const handleViewAllEpisode = () => {
    setAdvancedSearchOpen(true);
    setShowSearchBar(false);
  };

  const handleAddNewEpisode = (
    attribute: string,
    id: number,
    value: unknown
  ) => {
    const updatedModel = { ...model.find((x) => x.id === id) };
    switch (attribute) {
      case AssociatedEpisodeAttributeNames.episodeNumber:
        updatedModel.episodeId = value as string;
        checkDuplicateEpisodeId(updatedModel.episodeId);
        break;
      case AssociatedEpisodeAttributeNames.receivedDateTime:
        updatedModel.receivedDateTime = value;
        break;
      case AssociatedEpisodeAttributeNames.determinationDateTime:
        updatedModel.determinationDateTime = value as date;
        break;
      case AssociatedEpisodeAttributeNames.episodeDateTime:
        updatedModel.episodeDateTime = value;
        break;
      case AssociatedEpisodeAttributeNames.diagnosisCode:
        updatedModel.diagnosisCode = value as string;
        break;
      case AssociatedEpisodeAttributeNames.serviceCode:
        updatedModel.procedureCode = value as string;
        break;
      case AssociatedEpisodeAttributeNames.pathway:
        updatedModel.episodePathway = value as number;
        break;
      case AssociatedEpisodeAttributeNames.authorizationStatus:
        updatedModel.authorizationStatus = value as number;
        break;
      case AssociatedEpisodeAttributeNames.owner:
        updatedModel.ownerId = value as number;
        break;
      case AssociatedEpisodeAttributeNames.decisionBy:
        updatedModel.decisionById = value as number;
        break;
      case AssociatedEpisodeAttributeNames.parStatus:
        updatedModel.requestingClinicianPar = Boolean(value);
        break;
      case AssociatedEpisodeAttributeNames.provider:
        updatedModel.requestingProviderId = (value as Provider).id;
        updatedModel.provider = value as Provider;
        break;
      default:
        break;
    }
    const filterModel = model.filter((x) => x.id != id);
    const newModel = [
      ...filterModel,
      updatedModel,
    ] as AssociatedEpisodeActionValue[];
    setModel(newModel);
  };

  const handleCantFindEpisode = () => {
    const crypto = window.crypto;
    const unitArray = new Uint32Array(1);
    const newId = ((crypto.getRandomValues(unitArray)[0] / 100) | 0) * -1;
    const episode = { id: newId } as Episode;
    const newItem = {
      id: newId,
      episodeId: episode.episodeNumber,
      episodeDateTime: episode.createdOn,
      authorizationStatus: episode.authorizationStatus?.id,
      diagnosisCode: episode.diagnosisCodesAll?.map((x) => x?.code)?.join(', '),
      procedureCode: episode.procedureCodesAll?.map((x) => x?.code)?.join(', '),
      requestingProviderId: episode.requestingClinician?.id,
      nPI: episode.requestingClinician?.nPI,
      decisionDateTime: episode.faxDateTime,
      receivedDateTime: episode.faxDateTime,
      ownerId: episode.owner?.id,
      episodePathway: episode?.entityAttributes?.find(
        (x) => x?.name == AssociatedEpisodeAttributeNames.pathway
      )?.lookup?.id,
      determinationDateTime: episode?.determinationDate ?? null,
      requestingClinicianPar: Boolean(
        episode?.entityAttributes?.find(
          (x) =>
            x?.name == AssociatedEpisodeAttributeNames.requestingClinicianPar
        )?.value
      ),
      patientId: patientId,
    } as AssociatedEpisodeActionValue;
    setSelectedEpisodes([...selectedEpisodes, episode]);
    setModel([...model, newItem]);
    setShowSearchBar(false);
  };

  const handleInputChange = debounce(
    (value: AssociatedEpisodeActionValue[]) => {
      if (!checklistId) {
        return;
      }
      const data = {
        checklistId: Number(checklistId),
        componentId: INDIVIDUAL_COMPONENT_CODE,
        id: item.uid,
        type: 'string',
        value: JSON.stringify(value),
      };
      if (autoSave) {
        updateActionValueExtended(data, item.uid);
      } else {
        updateChecklistItemInputValueOnSave(item, orderableIndex, data);
      }
    },
    100
  );

  return (
    <>
      <Box id={`uuid-${item.uid}`} pl="64px" pr="64px">
        <Box sx={outerValidationStyle} pl="5px" pr="5px">
          <AssociatedEpisodeTable
            episodeAttributes={associatedEpisodeAttributeHelper}
            actionValues={model}
            patientId={patientId}
            onDelete={handleDeleteEpisode}
            handleChange={handleAddNewEpisode}
            isReview={false}
          />
          <Box pl="14px">
            {showSearchBar ? (
              <>
                <Box pt="14px">
                  <Typography>Use the search box below to add items</Typography>
                </Box>
                <EpisodeBox>
                  <EpisodeSearch
                    episodes={episodes}
                    searchTerm={searchTerm}
                    isReadOnly={isReadOnly}
                    isLoading={isAllEpisodeLoading || isSearchEpisodeLoading}
                    onSelect={handleEpisodeSelection}
                    onSearch={setSearchTerm}
                    onViewAllClick={handleViewAllEpisode}
                    onCantFindClick={handleCantFindEpisode}
                    totalCount={totalCount}
                  />
                  <Button
                    color="primary"
                    variant="text"
                    onClick={() => setAdvancedSearchOpen(true)}
                    disabled={isReadOnly}
                  >
                    Advanced Search
                  </Button>
                </EpisodeBox>
              </>
            ) : (
              <Box display="flex" pt="14px" pb="14px">
                <CustomTooltip title={addEpisodeButtonTooltipText} noMaxWidth>
                  <Button
                    variant="text"
                    color="primary"
                    size="small"
                    disabled={model.length >= maxAllowed || isReadOnly}
                    onClick={() => {
                      setShowSearchBar(true);
                      handleValidation(model, true);
                    }}
                    sx={buttonValidationStyle}
                    data-testid="btnAddAssociatedEpisode"
                  >
                    <Typography variant="body2">+ Add Episode Item </Typography>
                  </Button>
                </CustomTooltip>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <EpisodeSearchDialog
        open={advancedSearchOpen}
        episodes={episodes}
        isLoading={
          (isAllEpisodeLoading || isSearchEpisodeLoading) && searchTerm !== ''
        }
        onSearch={setSearchTerm}
        onSelect={handleEpisodeSelection}
        onClose={() => {
          setAdvancedSearchOpen(false);
        }}
        onCantFind={handleCantFindEpisode}
        totalCount={totalCount}
        patientId={patientId}
      />
    </>
  );
};
export default AssociatedEpisode;
