import { Cookies } from 'react-cookie';
import { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import client from '../graphql/baseApi';
import { IAuthUser } from '../backend/types/authUser';
import { authSuccess } from '../store/user/action';
import { getAuthUser } from '../store/roles/middlewares';

export const clearAuthUser = () => {
  const cookies = new Cookies();
  cookies.remove('.AspNetCore.Application.Id', { path: '/' });
  cookies.remove('.AspNetCore.Session', { path: '/' });
  cookies.remove('.AspNetCore.Identity.Application', { path: '/' });
  localStorage.removeItem('authUser');
};

export const authSuccessful = (
  result: AxiosResponse,
  dispatch: Dispatch,
  doRedirect = true
) => {
  const cookies = new Cookies();
  const token = result.data.token;
  cookies.set('.AspNetCore.Application.Id', token, { path: '/' });
  client.setHeader('authorization', `Bearer ${token}`);

  const authUser: IAuthUser = getAuthUser(result);
  localStorage.setItem('authUser', JSON.stringify(authUser));
  if (doRedirect) {
    if (authUser.redirectUrl) {
      window.location.href = authUser.redirectUrl;
    } else {
      dispatch(authSuccess(authUser));
    }
  } else {
    dispatch(authSuccess(authUser));
  }
};
