import React from 'react';
import { MedicationCode, PatientClaimDto } from 'graphql/graphqlTypes';
import ClaimsDetailsClose from './ClaimsDetailsClose';
import ClaimsDetailsItem from './ClaimsDetailsItem';
import { getMedicationCodesWithDescription } from 'components/actions/sections/SectionBody/Items/AssociatedClaim/AssociatedClaim.helper';

export const ClaimsDetails = (props: {
  selectedClaimId: number | null;
  setSelectedClaimId: (value: number | null) => void;
  claims: PatientClaimDto[];
}) => {
  const found = props.claims.find((x) => x.id === props.selectedClaimId);

  const getClaimAttribute = (
    name: keyof PatientClaimDto,
    subName?: keyof MedicationCode
  ) => (subName ? found?.[name]?.[subName] : found?.[name]);

  const claimEffectuated = found?.claimEffectuated ? 'Yes' : 'No';
  return (
    <>
      <ClaimsDetailsClose resetSelectedClaimId={props.setSelectedClaimId} />
      <div style={{ width: '100%' }}>
        <ClaimsDetailsItem
          value={getClaimAttribute('memberResponsibility')}
          label={'Member Responsibility'}
          isMoney={true}
        />
        <ClaimsDetailsItem
          value={getClaimAttribute('memberBenefitNetwork')}
          label={'Member Benefit Network'}
        />
        <ClaimsDetailsItem
          value={getClaimAttribute('billingProviderName')}
          label={'Billing Provider Name'}
        />
        <ClaimsDetailsItem
          value={getClaimAttribute('billingProviderTitle')}
          label={'Billing Provider Title'}
        />
        <ClaimsDetailsItem
          value={getClaimAttribute('performingProvider')}
          label={'Performing Provider'}
        />
        <ClaimsDetailsItem
          value={getClaimAttribute('serviceLocationName')}
          label={'Service Location Name'}
        />
        <ClaimsDetailsItem
          value={getMedicationCodesWithDescription(found?.diagnosisCodes)}
          label="Diagnosis Codes"
        />
        <ClaimsDetailsItem
          value={getMedicationCodesWithDescription(found?.procedureCodes)}
          label="Procedure Codes"
        />
        <ClaimsDetailsItem
          value={claimEffectuated}
          label={'Claim Effectuated'}
        />
        <ClaimsDetailsItem
          value={getClaimAttribute('lastModifiedDateTime')}
          label={'Last Modified Date/Time'}
          isDateTime={true}
        />
      </div>
    </>
  );
};
