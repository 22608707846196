import React, { useMemo } from 'react';
import UserFreeText from '../UserFreeText';
import DateComp from '../Date';
import DateTimeComp from '../DateTime';
import { ILookupValue } from 'backend/types/lookupValue';
import { ATTRIBUTE_TYPE } from 'backend/types/entityAttributeDef';
import LookupAttribute from './LookupAttribute';
import ReferenceAttribute from './ReferenceAttribute';
import BoolAttribute from './BoolAttribute';
import { IComponentProps } from '../types';
import { SxProps, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { IState } from 'store';
import { IEntityLookup, ILookup } from 'backend/types/lookup';
import {
  AttributeType,
  EntityAttributeComponent,
  IntAttributeType,
  LookupAttributeType,
  Maybe,
  ReferenceAttributeType,
  TextAttributeType,
  UserFreeTextComponent,
} from 'graphql/graphqlTypes';
import { formatDate, formatDateTime } from 'util/helpers/dateTimeHelpers';
import {
  DATE_FORMAT,
  DATETIME_FORMAT,
  DATETIME_SECONDS_FORMAT,
} from 'components/constants';

export interface IEntityAttributeProps
  extends IComponentProps<EntityAttributeComponent> {
  sectionItemId: string;
  handleSuggestItems: (
    entity: string,
    request: string,
    callback: (arg: ILookupValue[]) => void
  ) => void;
  autoSave: boolean;
  lastComponentInRow: boolean;
  isReadOnly: boolean;
  parentWidth?: number | string;
  isUpdateActionValueRequestFailed: boolean;
  hasCondition?: (value: string, conditionalValue: string) => boolean;
}

export const getFreeTextComponent = (
  attributeType: AttributeType | undefined,
  component: EntityAttributeComponent
): UserFreeTextComponent => {
  let maxLength = 0;
  let type = '';
  let multiline = false;
  if (attributeType?.type === ATTRIBUTE_TYPE.TEXT) {
    maxLength = (attributeType as TextAttributeType).maxLength || 0;
    type = 'string';
    multiline = component.multiline;
  } else if (attributeType?.type === ATTRIBUTE_TYPE.INT) {
    maxLength = (attributeType as IntAttributeType).maxLength || 0;
    type = 'number';
  } else if (attributeType?.type === ATTRIBUTE_TYPE.FLOAT) {
    type = 'float';
  }

  const freeTextComponent: UserFreeTextComponent = {
    componentType: 'UserFreeTextComponent',
    type: type,
    color: component.color,
    displayOnlyWhenSelected: component.displayOnlyWhenSelected,
    id: component.id,
    maxLen: maxLength,
    minLen: 0,
    maxVal: 0,
    minVal: 0,
    maxWidth: component.maxWidth,
    readonly: false,
    required: component.required,
    style: component.style,
    uniqueID: component.uniqueID,
    userDefinedId: component.userDefinedId,
    value: component.value,
    width: component.width,
    displayAsIcon: false,
    multiline: multiline,
    richText: false,
    useAutocomplete: component.autocomplete,
  };
  return freeTextComponent;
};

const EntityAttribute = (props: IEntityAttributeProps) => {
  const {
    component,
    handleChecklistInputChange,
    handleSuggestItems,
    sectionItemId,
    categoryId,
    isActionSelected,
    storageType,
    autoSave,
    lastComponentInRow,
    isReadOnly,
    parentWidth,
    isUpdateActionValueRequestFailed,
    hasCondition,
  } = props;

  const entityLookups = useSelector(
    (state: IState) =>
      state.checklist.documentsState[storageType].checklist?.entities
  ) as IEntityLookup[];

  const lookups = useSelector(
    (state: IState) =>
      state.checklist.documentsState[storageType].checklist?.lookups
  ) as ILookup[];

  const attributeTypes = useSelector(
    (state: IState) =>
      state.checklist.documentsState[storageType].checklist?.attributeTypes
  );

  const bigTableValues = useSelector(
    (state: IState) =>
      state.checklist.documentsState[storageType].checklist?.bigTableValues
  ) as IEntityLookup[];

  const sharedSx: SxProps | undefined = useMemo(() => {
    return component.color ? { color: component.color } : undefined;
  }, [component.color]);

  const getAttributeType = (fieldName: Maybe<string> | undefined) =>
    attributeTypes?.find((attr) => attr.fullName === fieldName);

  const getDateComponent = (
    dateComponent: EntityAttributeComponent,
    type: string
  ) => ({
    componentType: type,
    color: dateComponent.color,
    displayOnlyWhenSelected: dateComponent.displayOnlyWhenSelected,
    id: dateComponent.id,
    required: dateComponent.required,
    style: dateComponent.style,
    uniqueID: dateComponent.uniqueID,
    userDefinedId: dateComponent.userDefinedId,
    value: dateComponent.value,
    width: dateComponent.width,
    useSeconds: dateComponent.useSeconds,
    useCurrentDate: dateComponent.useCurrentDate || false,
    pastDatesNotAllowed: false,
    daysBeforeLimit: dateComponent.daysBeforeLimit,
    daysAfterLimit: dateComponent.daysAfterLimit,
    primaryComparison: dateComponent.primaryComparison,
    secondaryComparison: dateComponent.secondaryComparison,
  });

  switch (component.attributeType) {
    case ATTRIBUTE_TYPE.LOOKUP: {
      const lookupAttributeType = getAttributeType(
        component.fieldName
      ) as LookupAttributeType;
      return (
        <LookupAttribute
          component={component}
          lookups={lookups}
          entityLookups={entityLookups}
          attributeType={lookupAttributeType}
          storageType={storageType}
          handleChecklistInputChange={handleChecklistInputChange}
          handleSuggestItems={handleSuggestItems}
          isActionSelected={isActionSelected}
          categoryId={categoryId}
          autoSave={autoSave}
          isReadOnly={isReadOnly}
          parentWidth={parentWidth}
          isUpdateActionValueRequestFailed={isUpdateActionValueRequestFailed}
        />
      );
    }
    case ATTRIBUTE_TYPE.REFERENCE: {
      const referenceAttributeType = getAttributeType(
        component.fieldName
      ) as ReferenceAttributeType;
      return (
        <ReferenceAttribute
          component={component}
          entityLookups={entityLookups}
          bigTableValues={bigTableValues ?? []}
          storageType={storageType}
          attributeType={referenceAttributeType}
          handleChecklistInputChange={handleChecklistInputChange}
          handleSuggestItems={handleSuggestItems}
          isActionSelected={isActionSelected}
          categoryId={categoryId}
          autoSave={autoSave}
          isReadOnly={isReadOnly}
          parentWidth={parentWidth}
          isUpdateActionValueRequestFailed={isUpdateActionValueRequestFailed}
        />
      );
    }
    case ATTRIBUTE_TYPE.BOOL:
      return (
        <BoolAttribute
          component={component}
          handleChecklistInputChange={handleChecklistInputChange}
          sectionItemId={sectionItemId}
          categoryId={categoryId}
          storageType={storageType}
          isActionSelected={isActionSelected}
          autoSave={autoSave}
          isReadOnly={isReadOnly}
          isUpdateActionValueRequestFailed={isUpdateActionValueRequestFailed}
          hasCondition={hasCondition ?? (() => false)}
        />
      );
    case ATTRIBUTE_TYPE.DATE: {
      if (component.readonly) {
        return (
          <Typography variant="body2" sx={sharedSx}>
            {formatDate(component.value, DATE_FORMAT, true)}
          </Typography>
        );
      }
      return (
        <DateComp
          component={getDateComponent(component, 'DateComponent')}
          handleChecklistInputChange={handleChecklistInputChange}
          isActionSelected={isActionSelected}
          categoryId={categoryId}
          storageType={storageType}
          autoSave={autoSave}
          isReadOnly={isReadOnly}
          isUpdateActionValueRequestFailed={isUpdateActionValueRequestFailed}
          sectionItemId={sectionItemId}
          fieldName={component.fieldName}
        />
      );
    }
    case ATTRIBUTE_TYPE.DATETIME: {
      if (component.readonly) {
        return (
          <Typography variant="body1" sx={sharedSx}>
            {formatDateTime(
              component.value,
              component.useSeconds ? DATETIME_SECONDS_FORMAT : DATETIME_FORMAT
            )}
          </Typography>
        );
      }
      return (
        <DateTimeComp
          component={getDateComponent(component, 'DateTimeComponent')}
          handleChecklistInputChange={handleChecklistInputChange}
          isActionSelected={isActionSelected}
          categoryId={categoryId}
          storageType={storageType}
          autoSave={autoSave}
          isReadOnly={isReadOnly}
          isUpdateActionValueRequestFailed={isUpdateActionValueRequestFailed}
          sectionItemId={sectionItemId}
          fieldName={component.fieldName}
        />
      );
    }
    default: {
      if (component.readonly) {
        return (
          <Typography variant="body2" sx={sharedSx}>
            {!component.value ? 'N/A' : component.value}
          </Typography>
        );
      }
      const attributeType = getAttributeType(component.fieldName);
      const freeTextComponent = getFreeTextComponent(attributeType, component);
      return (
        <UserFreeText
          component={freeTextComponent}
          handleChecklistInputChange={handleChecklistInputChange}
          isActionSelected={isActionSelected}
          categoryId={categoryId}
          storageType={storageType}
          autoSave={autoSave}
          lastComponentInRow={lastComponentInRow}
          isReadOnly={isReadOnly}
          isUpdateActionValueRequestFailed={isUpdateActionValueRequestFailed}
          textAttribute={attributeType as TextAttributeType}
        />
      );
    }
  }
};
export default EntityAttribute;
