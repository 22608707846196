export interface IRolesState {
  items: IRole[];
  primaryRoleId: number | null;
  loading: boolean;
  error: string | null;
}

export interface IAccount {
  login: string;
  password: string;
  selectedRole: number;
  primaryRole: number;
}

export interface IRole {
  id: number;
  name: string;
  selected: boolean;
  primary: boolean;
  displayName: string;
  roleId: number;
  careSiteId: number;
  careSiteName: string;
  isOrganizationAccess?: boolean;
}

export enum RoleType {
  CLINICAL = 1,
  NONCLINICAL = 2,
  ADMIN = 3,
}

export enum Privilege {
  SystemAdministration = 0,
  MemberAdministration = 1,
  UserAdministration = 2,
  ManagerAdministration = 3,
  ModuleAdministration = 4,
  WorkflowAdministration = 5,
  LetterAdministration = 6,
  ReferenceAdministration = 7,
  CustomReportAdministration = 8,
  CreateEncounter = 9,
  AddMember = 10,
  EditMember = 11,
  AddLetters = 12,
  UpdateDocuments = 13,
  SSRSAccess = 14,
  DeleteAttachment = 15,
  ChangeOwner = 16,
  AmendDocuments = 17,
  EpisodeAdministration = 18,
  AdvancedSearch = 19,
  CSVDownloadAdministration = 20,
  EditSelected = 21,
  HealthServiceApproval = 22,
  LimitPHI = 23,
  TechnicalSupport = 24,
  HealthServices1_NODECISIONS = 25,
  HealthServices2_PENDINGONLY = 26,
  HealthServices3_ADDAPPROVALS = 27,
  HealthServices4_ADDDENIALS = 28,
  DeleteRestoreDocument = 29,
  Risk = 30,
  Education = 31,
  AllowAnyPHISearch = 32,
  EditMemberTab = 33,
  LimitEpisodesByUserProviders = 34,
  LimitEpisodesBySelectedUserProvider = 35,
  EditEpisodeDetailsState = 36,
  AddMemberEpisodeNotes = 37,
  ShowMozartRoutingSteps = 38,
  // Privilege 39 is used internally for BE purposes
  AllowFax = 40,
  ExportHomeScreen = 41,
  AllowMoveAttachment = 42,
  DeleteAdministration = 43,
  ComposerNavAdministration = 44,
  AllowRevertToPriorStepInMozart = 45,
  AllowSeeEpisodeSummary = 46,
  BulkAssign = 47,
  AutoAssign = 48,
}

export interface IRoleContext {
  selectedRole: number;
  primaryRole: number;
  sessionId: string;
}
