import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import styled from 'styled-components';
import { IState } from 'store';
import { Privilege } from 'store/roles/types';
import { ChecklistStorageType } from 'store/actions/types';
import { useChecklistForSidebar } from 'hooks/useChecklistForSidebar';
import { hasUserPrivilege } from 'util/helpers/privilegeHelper';
import { useLazyGetFormattedNoteAutocompleteTextQuery } from 'graphql/hooks/getFormattedNoteAutocompleteText';
import {
  UpdateEpisodeSummaryMutation,
  useUpdateEpisodeSummaryMutation,
} from 'graphql/hooks/updateEpisodeSummary';
import Panel from 'components/panel';
import Loader from 'components/loader';
import AutocompleteInput from 'components/actions/sections/SectionBody/Components/UserFreeText/autocompleteInput';
import {
  IMessageState,
  renderSaveAndCancelButtons,
  StyledBody,
  StyledError,
  SummaryInputProps,
} from './EpisodeSummary';

export interface IEpisodeSummaryEditProps {
  storageType: ChecklistStorageType;
  summary: string | undefined;
  setIsEditing: (isEditing: boolean) => void;
  refetchEpisodeSummaryHistory?: () => void;
  setIsEpisodeSummaryDirty?: (isDirty: boolean) => void;
}

const StyledHeader = styled.div`
  padding: 0px 2px 0px 14px;
  display: flex;
  justify-content: right;
  align-items: center;
`;

const StyledWrapper = styled.div`
  margin-bottom: 0px !important;
`;

const ViewWrapper = styled(Box)({
  width: '100%',
  paddingRight: '14px',
});

const EpisodeSummaryEdit = (props: IEpisodeSummaryEditProps) => {
  const [message, setMessage] = useState<IMessageState | null>();
  const [summary, setSummary] = useState(props.summary ?? '');
  const [selectedItem, setSelectedItem] = useState<string>();
  const { storageType, setIsEditing } = props;
  const { checklist } = useChecklistForSidebar(storageType);
  const episodeId = (checklist?.episodeId as number) ?? 0;
  const { refetchEpisodeSummaryHistory, setIsEpisodeSummaryDirty } = props;
  const isReadOnlyWorkflow = useSelector(
    (state: IState) => state.mozart.isReadOnlyWorkflow
  );
  const isReadOnly =
    storageType === ChecklistStorageType.MOZART && isReadOnlyWorkflow;

  const [updateEpisodeSummary, { isLoading: isEpisodeSummaryLoading }] =
    useUpdateEpisodeSummaryMutation();
  const [
    getFormattedNoteAutocompleteText,
    {
      data: formattedNoteAutocompleteTextResult,
      isLoading: isGetFormattedTextLoading,
      isSuccess: isGetFormattedTextSuccess,
      isFetching: isGetFormattedTextFetching,
    },
  ] = useLazyGetFormattedNoteAutocompleteTextQuery();

  const episodeSummaryPrivilege = hasUserPrivilege(
    Privilege.AllowSeeEpisodeSummary
  );

  useEffect(() => {
    if (!isGetFormattedTextFetching && isGetFormattedTextSuccess) {
      const formattedText =
        formattedNoteAutocompleteTextResult?.getFormattedNoteAutocompleteText ??
        '';
      if (selectedItem) {
        // Search value in the below replace method is based on the trigger condition in AutocompleteInput component
        const val = summary.replace(`.${selectedItem}. `, formattedText);
        setSummary(val);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isGetFormattedTextFetching,
    isGetFormattedTextSuccess,
    formattedNoteAutocompleteTextResult,
  ]);

  const handleSaveClick = () => {
    updateMessage(false, '');

    if (validateSummary(summary)) {
      updateEpisodeSummary({ episodeId: episodeId, summary: summary })
        .then((data) => {
          const result = data as { data: UpdateEpisodeSummaryMutation };
          if (!result?.data?.updateEpisodeSummary?.success) {
            updateMessage(
              true,
              result.data.updateEpisodeSummary?.message ?? ''
            );
          }
          setIsEditing(false);
          refetchEpisodeSummaryHistory?.();
          setIsEpisodeSummaryDirty?.(false);
        })
        .catch(() => {
          const errorText = 'Failed to save episode summary. Please try again.';
          updateMessage(true, errorText);
        });
    }
  };

  const updateMessage = (active: boolean, text: string) => {
    setMessage({
      active: active,
      text: text,
    });
  };

  const validateSummary = (summary: string) => {
    if (summary.length === 0) {
      updateMessage(true, 'Summary cannot be empty');
      return false;
    }
    if (summary.length > 4000) {
      updateMessage(true, 'Summary cannot exceed 4000 characters');
      return false;
    }
    return true;
  };

  const handleCancelClick = () => {
    updateMessage(false, '');
    setIsEditing(false);
  };

  const onInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const summaryText = event.target.value;
    setSummary(summaryText);
    updateMessage(false, '');
    setIsEpisodeSummaryDirty?.(true);
  };

  const onItemSelected = (value: string) => {
    setSelectedItem(value);
    getFormattedNoteAutocompleteText({
      episodeId: episodeId,
      searchText: value,
    });
  };

  const iconSize = 22;

  return (
    <StyledWrapper>
      <Panel padding="0px 0px 10px 0px">
        <StyledHeader>
          {!isReadOnly &&
            renderSaveAndCancelButtons(
              handleCancelClick,
              handleSaveClick,
              episodeSummaryPrivilege,
              iconSize
            )}
        </StyledHeader>

        <StyledError>
          <Typography>{message?.active ? message?.text : ''}</Typography>
        </StyledError>
        <StyledBody>
          <ViewWrapper>
            <AutocompleteInput
              key="episodeSummary"
              dataTestid="TextField_episodeSummary"
              disabled={false}
              autoFocus={true}
              error={message?.active ?? false}
              inputProps={SummaryInputProps}
              value={summary}
              onChange={onInputChange}
              onBlur={() => {
                return;
              }}
              onItemSelected={onItemSelected}
            />
          </ViewWrapper>
        </StyledBody>
      </Panel>
      <Loader active={isEpisodeSummaryLoading || isGetFormattedTextLoading} />
    </StyledWrapper>
  );
};

export default EpisodeSummaryEdit;
