import React, { ReactNode } from 'react';
import Header from 'components/header';
import { ModalContainer } from '.';
import Banner from 'components/banner';

interface IPageWrapperProps {
  children: ReactNode;
}

const PageWrapper = ({ children }: IPageWrapperProps) => {
  return (
    <div>
      <Header />
      <Banner />
      {children}
      <ModalContainer />
    </div>
  );
};

export default PageWrapper;
