import React, { useState } from 'react';
import Confirmation from 'components/modal/Confirmation';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';
import StyledLink from './StyledLink';
import { IAttachmentLinkProps } from './types';

interface IAttachmentGroupDeleteLinkProps extends IAttachmentLinkProps {
  isDisabled?: boolean;
  onDeleteCallback: (id: number) => void;
}

const AttachmentGroupDeleteLink = (props: IAttachmentGroupDeleteLinkProps) => {
  const { attachmentId, title, fileName, isDisabled, onDeleteCallback } = props;
  const [
    showAttachmentDeleteConfirmation,
    setShowAttachmentDeleteConfirmation,
  ] = useState(false);

  return (
    <>
      <CustomTooltip title={title ?? 'Delete'}>
        <StyledLink
          onClick={() => setShowAttachmentDeleteConfirmation(true)}
          data-cy="Delete"
          $isDisabled={isDisabled}
        >
          Delete
        </StyledLink>
      </CustomTooltip>
      <Confirmation
        open={showAttachmentDeleteConfirmation}
        title="Delete Attachment?"
        okButtonText="Delete"
        okEvent={() => {
          setShowAttachmentDeleteConfirmation(false);
          onDeleteCallback(attachmentId);
        }}
        cancelEvent={() => setShowAttachmentDeleteConfirmation(false)}
      >
        <span>{`Are you sure you want to delete the attachment ${fileName}? `}</span>
      </Confirmation>
    </>
  );
};

export default AttachmentGroupDeleteLink;
